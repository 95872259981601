import React from 'react';
import { urlHelper } from '../../../constants/common/url/urlHelpers';
import { ErrorBrokerageCompany } from '../../parts/Common/ErrorBrokerageCompany';

export const NotFoundBrokerageCompanyPage: React.FC = () => (
  <ErrorBrokerageCompany
    title="404 Page not found"
    subtitle="お探しのページは見つかりませんでした"
    buttonAttributes={[{ href: urlHelper.top(), text: 'TOPへ' }]}
    isZishaGyoshakan={false}
    activeMenu="rent"
  />
);
