import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Footer } from './Footer';
import { ErrorMessageArea } from './ErrorMessageArea';
import { ITANDIBBLogo } from './BrokerageCompanyHeader/ITANDIBBLogo';
import { ITANDIBBPlusLogo } from './ManagementCompanyHeader/ITANDIBBPlusLogo';

// material-ui公式のThemeの型を利用してもprefer-readonly-parameter-typesが消えないため
// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
const useStyles = makeStyles((theme: Theme) => ({
  Body: {
    backgroundColor: theme.palette.common.white
  },
  Main: {
    minHeight: 'calc(100vh - 48px - 84px)',
    padding: '80px'
  },
  Logo: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    height: '48px'
  },
  LeftLogo: {
    height: '18px',
    margin: '14px 24px 14px 24px'
  },
  RightLogo: {
    height: '22px',
    margin: '12px 0'
  },
  // Footer component内で呼ばれている、BaseStyleのstyleの書き方に依存しているので注意
  FooterWrapper: {
    '& > *': {
      position: 'static'
    }
  }
}));

type Props = Readonly<{
  title: string;
  subtitle: string;
  buttonAttributes: ReadonlyArray<{ text: string; href: string }>;
}>;

// ヘッダーなし：エラー
// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
export const Error: React.FC<Props> = ({
  title,
  subtitle,
  buttonAttributes
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.Logo}>
        <div className={classes.LeftLogo}>
          <ITANDIBBLogo />
        </div>
        <div className={classes.RightLogo}>
          <ITANDIBBPlusLogo />
        </div>
      </div>
      <div className={classes.Body}>
        <div className={classes.Main}>
          <ErrorMessageArea
            title={title}
            subtitle={subtitle}
            buttonAttributes={buttonAttributes}
          />
        </div>
        <div className={classes.FooterWrapper}>
          <Footer />
        </div>
      </div>
    </>
  );
};
