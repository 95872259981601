import React from 'react';
import { urlHelper } from '../../../constants/common/url/urlHelpers';
import { ErrorBrokerageCompany } from '../../parts/Common/ErrorBrokerageCompany';

export const ServerErrorBrokerageCompanyPage: React.FC = () => (
  <ErrorBrokerageCompany
    title="500 internal server error"
    subtitle="システム管理者へご連絡下さい"
    buttonAttributes={[{ href: urlHelper.top(), text: 'TOPへ' }]}
    isZishaGyoshakan={false}
    activeMenu="rent"
  />
);
