import { OptionTypeBase } from 'react-select/src/types';
import { additionalSearchBucketSize } from './propertyList';

// formから取得する値は文字列になるのでapiで認識される型に変換する対象リスト
export const generateStringToApprovedTypeList = [
  'station_walk_minutes:lteq',
  'floor_area_amount:gteq',
  'floor_area_amount:lteq',
  'land_area_amount:gteq',
  'land_area_amount:lteq',
  'building_age:lteq',
  'option_id:all_in',
  'floor:gteq'
];

// Optionのデフォルト値
export const defaultOptionIncludeAggregation = {
  filter: {},
  page: {
    page: 1,
    limit: 10
  },
  sort: [
    {
      last_status_opened_at: 'desc'
    }
  ],
  aggregation: {
    field: 'building_id',
    bucket_size: additionalSearchBucketSize.base,
    next_bucket_existance_check: true
  }
};

export const checkBoxListnearbyTrainStationWithBusMinutes: Readonly<OptionTypeBase> =
  {
    nearbyTrainStationWithBusMinutesCheck: {
      true: 'バス乗車時間を含む'
    }
  };

export const checkBoxListResidentialBuildingDetailType = {
  'building_detail_type:in': {
    mansion: 'マンション',
    apartment: 'アパート',
    detached_house: '一戸建て',
    terraced_house: 'テラスハウス',
    town_house: 'タウンハウス',
    share_house: 'シェアハウス'
  }
};

export const checkBoxListBusinessBuildingDetailType = {
  'building_detail_type:in': {
    store: '店舗',
    office: '事務所',
    store_and_office: '店舗・事務所',
    house_with_store: '店舗付住宅',
    store_with_house: '住宅付店舗',
    mansion: 'マンション',
    building: 'ビル',
    factory: '工場',
    warehouse: '倉庫'
  }
};

export const checkBoxList: Readonly<OptionTypeBase> = {
  'layout:in': {
    '1R': '1R',
    '1K': '1K',
    '1DK': '1DK',
    '1LDK': '1LDK',
    '2K': '2K',
    '2DK': '2DK',
    '2LDK': '2LDK',
    '3K': '3K',
    '3DK': '3DK',
    '3LDK': '3LDK',
    '4K': '4K',
    '4DK': '4DK',
    '4LDK': '4LDK'
  },
  'structure_type:in': {
    wooden: '木造',
    block: 'ブロック',
    reinforcing_block: '鉄筋ブロック',
    steel: '鉄骨造',
    lightweight_steel: '軽量鉄骨造',
    rc: 'RC',
    src: 'SRC',
    pc: 'PC',
    hpc: 'HPC',
    alc: 'ALC',
    cft: 'CFT'
  },
  'floor:eq': {
    1: '1階'
  },
  'floor:gteq': {
    2: '2階以上'
  },
  'parking_exists:eq': { true: '駐車場あり' },
  'main_lighting_direction_type:in': {
    north: '北',
    north_east: '北東',
    east: '東',
    south_east: '南東',
    south: '南',
    south_west: '南西',
    west: '西',
    north_west: '北西'
  },
  'land_yoto_chiiki_type:in': {
    class1_low_rise: '1種低層',
    class2_low_rise: '2種低層',
    class1_medium_high: '1種中高',
    class2_medium_high: '2種中高',
    class1_residential: '1種住居',
    class2_residential: '2種住居',
    quasi_residential: '準住居',
    neighborhood_commercial: '近隣商業',
    commercial_district: '商業地域'
  },
  'land_optimal_usage_type:in': {
    residential: '住宅',
    condominium: 'マンション',
    building: 'ビル',
    store: '店舗',
    office: '事務所',
    parking: '駐車場'
  }
};

// テレビ
export const checkBoxListOptionTv: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    15011: 'CATV',
    15010: 'BS',
    15013: 'CS'
  }
};

// インターネット
export const checkBoxListOptionInternet: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    15021: 'インターネット無料',
    15020: 'インターネット対応'
  }
};

// バス・トイレ
export const checkBoxListOptionBathroom: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    10050: '専用トイレ',
    11020: '温水洗浄便座',
    11010: 'バス・トイレ別',
    10040: '専用バス',
    11040: '追い焚き機能',
    11050: '浴室乾燥機'
  }
};

// 水回りその他
export const checkBoxListOptionBasinArea: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    11060: '独立洗面台',
    12040: '給湯',
    11080: '室内洗濯機置き場',
    11081: '洗濯機置き場'
  }
};

// ガス
export const checkBoxListOptionGas: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    10020: '都市ガス',
    10021: 'プロパンガス'
  }
};

// キッチン
export const checkBoxListOptionKitchen: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    12010: 'システムキッチン',
    12020: 'IHクッキングヒーター',
    12032: 'コンロ2口',
    12033: 'コンロ3口以上',
    12030: 'ガスコンロ設置可',
    12060: '食器洗浄乾燥機'
  }
};

// 空調
export const checkBoxListOptionAirConditioning: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    13010: 'エアコン',
    13020: '床暖房'
  }
};

// 収納
export const checkBoxListOptionStorageSpace: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    // 収納スペースの14030（床下収納）はcreateSearchOption内で追加
    14010: '収納スペース',
    14012: 'ウォークインクローゼット',
    14020: 'シューズボックス'
  }
};

// 室外スペース
export const checkBoxListOptionOutdoorSpace: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    19070: 'バルコニー',
    19080: '庭'
  }
};

// セキュリティ
export const checkBoxListOptionSecurity: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    16010: 'オートロック',
    16011: 'モニター付きインターホン',
    16020: 'ディンプルキー',
    16030: '防犯カメラ',
    16031: '24時間セキュリティ'
  }
};

// 部屋情報その他
export const checkBoxListOptionOther: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    12021: 'オール電化',
    13040: '複層ガラス',
    19030: 'フローリング',
    19010: '家具付き',
    19050: 'ロフト'
  }
};

// 入居条件（属性）
export const checkBoxListOptionConditionsAttribute: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    22030: '女性限定',
    22032: '学生限定',
    22040: '二人入居可',
    22035: '単身者向き',
    22045: '外国籍可'
  }
};

// 入居条件（法人）
export const checkBoxListOptionConditionsCorporation: Readonly<OptionTypeBase> =
  {
    'option_id:all_in': {
      22050: '事務所可',
      22060: '法人契約限定',
      22061: '法人契約希望'
    }
  };

// 入居条件（その他）
export const checkBoxListOptionConditionsOther: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    22010: 'ペット相談',
    22020: '楽器相談'
  }
};

// 建物
export const checkBoxListOptionBuilding1: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    21020: '宅配ボックス'
  }
};

export const checkBoxListOptionBuilding2: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    19040: 'エレベーター',
    21031: '24時間ゴミ出し可',
    21050: 'バリアフリー',
    20580: 'ZEH'
  }
};

// 駐車場等
export const checkBoxListOptionParkingLot: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    19092: '駐車場2台分',
    19090: '駐輪場',
    19091: 'バイク置き場'
  }
};

export const checkBoxListOptionTopFloor: Readonly<OptionTypeBase> = {
  'option_id:all_in': {
    20031: '最上階'
  }
};

export const checkBoxListOptionTowerMansion: Readonly<OptionTypeBase> = {
  'floor_height:gteq': {
    20: 'タワーマンション'
  }
};

// 駅・管理会社名検索フォームの入力遅延時間
export const LineOrManagementCompaniesSelectFormDebounceTime = 500;
