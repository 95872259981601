import qs from 'qs';
import { bbApiOrigin } from '../../constants/common/application';

export const buildQueryString = (
  params: Readonly<{
    [key: string]:
      | string
      | number
      | boolean
      | ReadonlyArray<string | number | boolean>;
  }>
): string => qs.stringify(params, { arrayFormat: 'brackets' });

export const apiURLPrefix = `${bbApiOrigin}/api/internal`;

export const buildPropertyOfferZumenUrl = (
  propertyId: number,
  offerZumenNumber: number,
  requestPage: 'detail_page' | 'search_result_page',
  isZumenDownload: boolean
): string =>
  `${apiURLPrefix}/v4/properties/${propertyId}/offer_zumens/${offerZumenNumber}?request_page=${requestPage}&is_zumen_download=${isZumenDownload}`;
