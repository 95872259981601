import { useState, useCallback } from 'react';

export const useBool = (
  initialValue: boolean
): [boolean, () => void, () => void] => {
  const [value, setValue] = useState<boolean>(initialValue);

  const setTrue = useCallback((): void => setValue(true), []);
  const setFalse = useCallback((): void => setValue(false), []);

  return [value, setTrue, setFalse];
};
