import React, { useMemo, createContext, useState } from 'react';
import { searchFormFieldsListRentLandDefault } from '../constants/rentProperty';
import { SearchablePropertySort } from '../types/propertySearchParams/sort';
import { JgdcCode } from '../types/jgdcCode';
import { useSearchFormJgdcCodes } from '../hooks/useSearchFormJgdcCodes';
import { PropertySearchModeLimited } from '../types/propertySearchParams/mode';
import { useBool } from '../hooks/util/useBool';

export const RentLandGlobalContext = createContext(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as {
    isRentLandListDrawerOpened: boolean;
    openRentLandListDrawer: () => void;
    closeRentLandListDrawer: () => void;
    rentLandSearchSelected: Readonly<
      typeof searchFormFieldsListRentLandDefault
    >;
    setRentLandSearchSelected: (
      searchSelected: typeof searchFormFieldsListRentLandDefault
    ) => void;
    rentLandMode: PropertySearchModeLimited;
    setRentLandMode: (mode: PropertySearchModeLimited) => void;
    searchFormJgdcCodes: ReadonlyArray<JgdcCode>;
    setFilteredSearchFormJgdcCodes: (
      jgdcCodes: ReadonlyArray<JgdcCode>
    ) => void;
    rentLandOffset: number;
    setRentLandOffset: (offset: number) => void;
    rentLandSelectedSort: SearchablePropertySort;
    setRentLandSelectedSort: (sort: SearchablePropertySort) => void;
  }
);

type Props = Readonly<{
  children: React.ReactNode;
}>;

export const RentLandGlobalContextProvider: React.FC<Props> = ({
  children
}) => {
  const [
    isRentLandListDrawerOpened,
    openRentLandListDrawer,
    closeRentLandListDrawer
  ] = useBool(true);
  const [rentLandSearchSelected, setRentLandSearchSelected] = useState<
    Readonly<typeof searchFormFieldsListRentLandDefault>
  >(searchFormFieldsListRentLandDefault);
  const [rentLandMode, setRentLandMode] =
    useState<PropertySearchModeLimited>('single');
  const [searchFormJgdcCodes, setFilteredSearchFormJgdcCodes] =
    useSearchFormJgdcCodes();
  const [rentLandOffset, setRentLandOffset] = useState<number>(1);
  const [rentLandSelectedSort, setRentLandSelectedSort] =
    useState<SearchablePropertySort>('last_status_opened_at desc');

  const contextValue = useMemo(
    () => ({
      isRentLandListDrawerOpened,
      openRentLandListDrawer,
      closeRentLandListDrawer,
      rentLandSearchSelected,
      setRentLandSearchSelected,
      rentLandMode,
      setRentLandMode,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      rentLandOffset,
      setRentLandOffset,
      rentLandSelectedSort,
      setRentLandSelectedSort
    }),
    [
      isRentLandListDrawerOpened,
      openRentLandListDrawer,
      closeRentLandListDrawer,
      rentLandSearchSelected,
      setRentLandSearchSelected,
      rentLandMode,
      setRentLandMode,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      rentLandOffset,
      setRentLandOffset,
      rentLandSelectedSort,
      setRentLandSelectedSort
    ]
  );

  return (
    <RentLandGlobalContext.Provider value={contextValue}>
      {children}
    </RentLandGlobalContext.Provider>
  );
};
