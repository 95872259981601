import { useState } from 'react';
import { JgdcCode } from '../types/jgdcCode';

export const useSearchFormJgdcCodes = (): [
  ReadonlyArray<JgdcCode>,
  (JgdcCodes: ReadonlyArray<JgdcCode>) => void
] => {
  const [searchFormJgdcCodes, setSearchFormJgdcCodes] = useState<
    ReadonlyArray<JgdcCode>
  >([]);

  const setFilteredSearchFormJgdcCodes = (
    jgdcCodes: ReadonlyArray<JgdcCode>
  ): void => setSearchFormJgdcCodes(jgdcCodes.slice(0, 10));

  return [searchFormJgdcCodes, setFilteredSearchFormJgdcCodes];
};
