import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { ReloadPageButton } from './parts/Button/ReloadPageButton';
import { apiURLPrefix } from '../utils/api/urls';

export const Login = (): JSX.Element => {
  useEffect(() => {
    const URL = `${apiURLPrefix}/sessions/new`;
    window.location.href = URL;
  }, []);

  return (
    <Box m={3}>
      5秒経ってもページが自動で切り替わらない場合は、下部の「ページを更新する」をクリックするか、ページ更新してください。
      <ReloadPageButton />
    </Box>
  );
};
