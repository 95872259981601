import { useState, useEffect } from 'react';
import { getZishaGyoshakanMasterLogoImage } from '../utils/api/zishaGyoshakanMasters/logoImage';

export const useZishaGyoshakanMasterLogoImage = (
  needFetchingLogoImage: boolean,
  zishaGyoshakanKey: string
): string | null => {
  const [logoImageUrl, setLogoImageUrl] = useState<string | null>(null);

  useEffect(() => {
    (async (): Promise<void> => {
      if (!needFetchingLogoImage || !zishaGyoshakanKey) return;
      const [result, status] =
        await getZishaGyoshakanMasterLogoImage(zishaGyoshakanKey);
      if (status === 'successful' && !('message' in result)) {
        setLogoImageUrl(result.url);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zishaGyoshakanKey]);
  return logoImageUrl;
};
