import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '../../../theme/palette';

const useStyles = makeStyles(() => ({
  FooterWrapper: {
    width: '100%',
    height: 80,
    backgroundColor: palette.common.white
  },
  Footer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    padding: '32px 16px',
    color: '#9e9e9e',
    marginTop: 'auto'
  }
}));

// フッター
export const Footer = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.FooterWrapper}>
      <div className={classes.Footer}>
        © {new Date().getFullYear()} ITANDI, Inc.
      </div>
    </div>
  );
};
