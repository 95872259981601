import { palette } from '../palette';

export const MuiInput = {
  root: {},
  underline: {
    '&:after': {
      borderBottom: `2px solid ${palette.primary.main}`
    },
    '&:hover:not($disabled):before': {
      borderBottom: `2px solid ${palette.primary.main}`,
      '@media (hover: none)': {
        borderBottom: `1px solid ${palette.primary.main}`
      }
    }
  }
};
