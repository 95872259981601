import {
  InternalServerErrorResponse,
  SuccessfulResponse,
  UnauthorizedResponse,
  client,
  internalServerErrorResponse
} from '../client';
import { emptyLoginInfo } from '../../hooks/useLoginInfoOrRedirect';
import { urlHelper } from '../../constants/common/url/urlHelpers';
import { LoginInfo } from '../../types/loginInfo';
import { apiURLPrefix, buildQueryString } from './urls';

export type GetItandiAccountsCallbackQuery = Readonly<{
  access_token: string;
  state: string;
  token_type: string;
}>;

type ItandiAccountsCallbackResponse = Readonly<{
  csrf_token: string;
}>;

type GEtItandiAccountsCallbackResponseBody =
  | SuccessfulResponse<ItandiAccountsCallbackResponse>
  | UnauthorizedResponse
  | InternalServerErrorResponse;

export const getItandiAccountsCallback = async (
  query: GetItandiAccountsCallbackQuery
): Promise<GEtItandiAccountsCallbackResponseBody> => {
  const result =
    await client.getOnlyThrowTooManyRequestAndInternalServerError<ItandiAccountsCallbackResponse>(
      `${apiURLPrefix}/sessions/itandi_accounts_callback?${buildQueryString(query)}`
    );
  if (
    result[1] === 'badRequest' ||
    result[1] === 'notFound' ||
    result[1] === 'forbidden'
  ) {
    return internalServerErrorResponse;
  }
  return result;
};

// ログイン情報
export const getLoginInfo = async (): Promise<LoginInfo> => {
  const result = await client.getFromPublicPage<LoginInfo>(
    `${apiURLPrefix}/sessions/authenticate`
  );
  if (result[1] === 'successful') {
    return result[0];
  }
  window.location.href = urlHelper.error500();
  return emptyLoginInfo;
};
