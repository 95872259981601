import React from 'react';
import { urlHelper } from '../../../constants/common/url/urlHelpers';
import { ErrorBrokerageCompany } from '../../parts/Common/ErrorBrokerageCompany';

type Props = Readonly<{
  zishaGyoshakanKey: string;
}>;

export const NotFoundZishaGyoshakanBrokerageCompanyPage: React.FC<Props> = ({
  zishaGyoshakanKey
}: Props) => (
  <ErrorBrokerageCompany
    title="404 Page not found"
    subtitle="お探しのページは見つかりませんでした"
    buttonAttributes={[
      {
        href: urlHelper.zishaGyoshakanRentRoomsList(zishaGyoshakanKey),
        text: '賃貸物件検索へ'
      }
    ]}
    isZishaGyoshakan
    activeMenu="rentRoom"
  />
);
