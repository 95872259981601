import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { errorMsg } from '../../../constants/common/msgConstant';
import {
  BrokerageCompanyHeader,
  BrokerageCompanyHeaderActiveMenu
} from './BrokerageCompanyHeader/BrokerageCompanyHeader';
import { Footer } from './Footer';
import { useNotifications } from '../../../hooks/useNotifications';
import { BrokerageCompanyMain } from '../../BrokerageCompany/Main';
import { ErrorMessageArea } from './ErrorMessageArea';
import { useTrigger } from '../../../hooks/util/useTrigger';

// material-ui公式のThemeの型を利用してもprefer-readonly-parameter-typesが消えないため
// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
const useStyles = makeStyles((theme: Theme) => ({
  Body: {
    backgroundColor: theme.palette.common.white
  },
  MessageWrapper: {
    marginTop: '20px',
    textAlign: 'center',
    marginBottom: '40px'
  },
  // Footer component内で呼ばれている、BaseStyleのstyleの書き方に依存しているので注意
  FooterWrapper: {
    '& > *': {
      position: 'static'
    }
  }
}));

type Props = Readonly<{
  title: string;
  subtitle: string;
  buttonAttributes: ReadonlyArray<{ text: string; href: string }>;
  isZishaGyoshakan: boolean;
  activeMenu: BrokerageCompanyHeaderActiveMenu;
}>;

// 仲介会社ヘッダー：エラー
// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
export const ErrorBrokerageCompany: React.FC<Props> = ({
  title,
  subtitle,
  buttonAttributes,
  isZishaGyoshakan,
  activeMenu
}: Props): JSX.Element => {
  const classes = useStyles();
  const [notificationsBadgeCountTrigger, toggleNotificationsBadgeCountTrigger] =
    useTrigger();
  const [notifications, notificationsBadgeCount] = useNotifications(
    notificationsBadgeCountTrigger
  );

  return (
    <div className={classes.Body}>
      <BrokerageCompanyHeader
        notifications={notifications}
        notificationsBadgeCount={notificationsBadgeCount}
        toggleNotificationsBadgeCountTrigger={
          toggleNotificationsBadgeCountTrigger
        }
        isZishaGyoshakan={isZishaGyoshakan}
        activeMenu={activeMenu}
      />
      <BrokerageCompanyMain>
        <div className={classes.MessageWrapper}>
          <ErrorMessageArea
            title={title}
            subtitle={subtitle}
            buttonAttributes={buttonAttributes}
          />
        </div>
      </BrokerageCompanyMain>
      <div className={classes.FooterWrapper}>
        <Footer />
      </div>
    </div>
  );
};

ErrorBrokerageCompany.defaultProps = {
  title: errorMsg.ms500,
  subtitle: errorMsg.sub500
};
