import _ from 'lodash';
import { OptionTypeBase } from 'react-select/src/types';
import {
  env,
  ImageSlideCountMobile,
  ImageSlideCountTablet
} from '../constants/common/application';

// 空のform要素を取り除く
export const removeEmpty = (val: Readonly<OptionTypeBase>): OptionTypeBase =>
  /* eslint-disable @typescript-eslint/prefer-readonly-parameter-types, no-param-reassign, @typescript-eslint/no-unsafe-return */
  _.transform(val, (result: OptionTypeBase, value, key) => {
    if (typeof value === 'number') {
      result[key] = value;
    }
    if (typeof value === 'boolean') {
      if (value) {
        result[key] = value;
      }
    }
    if (!_.isEmpty(value)) {
      if (typeof value === 'object') {
        result[key] = removeEmpty(value);
      } else {
        result[key] = value;
      }
    }
  });
/* eslint-disable @typescript-eslint/prefer-readonly-parameter-types, no-param-reassign */

export const nl2br = (str: string): string => str.replace(/\n/g, '<br>');

export const sortArray = (ary: string[]): string[] =>
  ary.sort((a: string, b: string) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });

// 賃貸：form入力時のエラージャンプ
export const returnErrorLink = (name: string): number | string => {
  if (name === 'building_name:match') {
    return 1;
  }
  if (name === 'station_walk_minutes:lteq') {
    return 3;
  }
  if (name === 'rent:gteq' || name === 'rent:lteq') {
    return 2;
  }
  if (name === 'square:gteq' || name === 'square:lteq') {
    return 4;
  }
  if (name === 'building_age:lteq') {
    return 6;
  }
  return '';
};

// Jsonへ変換（変換のためにany利用)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const returnJsonArray = (data: unknown): any => {
  const resultJson = JSON.stringify(data);
  return JSON.parse(resultJson);
};

export const minSize = window.matchMedia('(max-width: 549px)');
export const minSizeLaptopAndTablet = window.matchMedia('(min-width: 550px)');
export const minSizeLaptopAndTabletHorizontal =
  window.matchMedia('(min-width: 900px)');
export const minSizeLaptop = window.matchMedia('(min-width: 1200px)');

// TODO: 移動
// 賃貸物件詳細画面画像カウント取得
export const returnSlideCount = (totalCount: number): number => {
  // eslint-disable-next-line no-nested-ternary
  const SlideCount = minSizeLaptopAndTabletHorizontal.matches
    ? totalCount
    : minSize.matches
      ? ImageSlideCountMobile
      : ImageSlideCountTablet;
  if (totalCount <= SlideCount) {
    return totalCount;
  }
  return SlideCount;
};

export const cookieKeyBBto = 'BBto';

export const cookieKeyCsrfToken =
  env === 'production' ? 'CSRF-TOKEN' : `${env}-CSRF-TOKEN`;
