import { useState } from 'react';
import {
  minSize,
  minSizeLaptop,
  minSizeLaptopAndTablet,
  minSizeLaptopAndTabletHorizontal
} from '../utils/dataUtil';

// ブレイクポイント通過を検知
export const useWatchMediaQueryChange = (): {
  isLaptop: boolean;
  isLaptopAndTabletHorizontal: boolean;
  isLaptopAndTablet: boolean;
  isMobile: boolean;
} => {
  // PCのみ
  const [isLaptop, setIsLaptop] = useState<boolean>(minSizeLaptop.matches);
  // PCとタブレット横
  const [isLaptopAndTabletHorizontal, setIsLaptopAndTabletHorizontal] =
    useState<boolean>(minSizeLaptopAndTabletHorizontal.matches);
  // PCとタブレット（縦）
  const [isLaptopAndTablet, setIsLaptopAndTablet] = useState<boolean>(
    minSizeLaptopAndTablet.matches
  );
  // モバイル
  const [isMobile, setIsMobile] = useState<boolean>(minSize.matches);

  minSizeLaptop.onchange = (e: Readonly<MediaQueryListEvent>): void => {
    if (e.matches) {
      setIsLaptop(true);
    } else {
      setIsLaptop(false);
    }
  };

  minSizeLaptopAndTabletHorizontal.onchange = (
    e: Readonly<MediaQueryListEvent>
  ): void => {
    if (e.matches) {
      setIsLaptopAndTabletHorizontal(true);
    } else {
      setIsLaptopAndTabletHorizontal(false);
    }
  };

  minSizeLaptopAndTablet.onchange = (
    e: Readonly<MediaQueryListEvent>
  ): void => {
    if (e.matches) {
      setIsLaptopAndTablet(true);
    } else {
      setIsLaptopAndTablet(false);
    }
  };

  minSize.onchange = (e: Readonly<MediaQueryListEvent>): void => {
    if (e.matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  return { isLaptop, isLaptopAndTabletHorizontal, isLaptopAndTablet, isMobile };
};
