import React from 'react';
import { RentRoomGlobalContextProvider } from './RentRoomGlobalContext';
import { SaleRoomGlobalContextProvider } from './SaleRoomGlobalContext';
import { GlobalContextProvider } from './GlobalContext';
import { FolloweeRentRoomGlobalContextProvider } from './FolloweeRentRoomGlobalContext';
import { RentBusinessPropertyGlobalContextProvider } from './RentBusinessPropertyGlobalContext';
import { RentLandGlobalContextProvider } from './RentLandGlobalContext';
import { SaleLandGlobalContextProvider } from './SaleLandGlobalContext';
import { SaleBusinessPropertyGlobalContextProvider } from './SaleBusinessPropertyGlobalContext';
import { RentParkingGlobalContextProvider } from './RentParkingGlobalContext';
import { HeaderContextProvider } from './HeaderContext';

// Providerを結合
/* eslint-disable @typescript-eslint/prefer-readonly-parameter-types, react/jsx-props-no-spreading */
const composeGlobalComponents = (
  ...components: React.FC<Readonly<{ children: React.ReactNode }>>[]
): React.FC<Readonly<{ children: React.ReactNode }>> => {
  // 1つだけの場合は、そのまま返す
  if (components.length === 1) {
    return components[0];
  }
  return components.reduce(
    (A, B) =>
      (props): JSX.Element => (
        <A>
          <B {...props} />
        </A>
      )
  );
};

// Global系のContextを結合
// NOTE: SaleRoomなど、Global系のContextが増える度にここに記載すると反映されます
const ComposedGlobalProvider = composeGlobalComponents(
  GlobalContextProvider,
  RentRoomGlobalContextProvider,
  FolloweeRentRoomGlobalContextProvider,
  SaleRoomGlobalContextProvider,
  RentBusinessPropertyGlobalContextProvider,
  RentLandGlobalContextProvider,
  SaleLandGlobalContextProvider,
  SaleBusinessPropertyGlobalContextProvider,
  RentParkingGlobalContextProvider,
  HeaderContextProvider
);

type Props = Readonly<{
  children: React.ReactNode;
}>;

// Global系のContext結合用Provider
export const ComposedGlobalContextProvider: React.FC<Props> = ({
  children
}) => <ComposedGlobalProvider>{children}</ComposedGlobalProvider>;
