import { useState, useEffect } from 'react';
import { getZishaGyoshakanBrokerageCompanyContact } from '../utils/api/zishaGyoshakanMasters/contact';
import { ZishaGyoshakanContact } from '../types/zishaGyoshakanContact';

export const useZishaGyoshakanBrokerageCompanyContact = (
  zishaGyoshakanMasterKey: string,
  callable: boolean
): Readonly<[ZishaGyoshakanContact, boolean]> => {
  const [
    zishaGyoshakanBrokerageCompanyContact,
    setZishaGyoshakanBrokerageCompanyContact
  ] = useState<ZishaGyoshakanContact>({
    email: '',
    phone_number: ''
  });
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    (async (): Promise<void> => {
      if (!callable) return;
      const [result, status] = await getZishaGyoshakanBrokerageCompanyContact(
        zishaGyoshakanMasterKey
      );

      if (status === 'successful' && !('message' in result)) {
        setZishaGyoshakanBrokerageCompanyContact(result);
      }
      setLoaded(true);
    })();
  }, [zishaGyoshakanMasterKey, callable]);

  return [zishaGyoshakanBrokerageCompanyContact, loaded];
};
