import React from 'react';
import { urlHelper } from '../../constants/common/url/urlHelpers';
import { ErrorTemplate } from '../../components/templates/Error/ErrorTemplate';

export const ServerErrorPage: React.FC = () => (
  <ErrorTemplate
    title="500 internal server error"
    subtitle="システム管理者へご連絡下さい"
    buttonAttributes={[{ href: urlHelper.top(), text: 'TOPへ' }]}
  />
);
