import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

export const ReloadPageButton = (): JSX.Element => {
  const handleReload = (): void => {
    window.location.reload();
  };

  return (
    <Box mt={2}>
      <Button onClick={handleReload} variant="outlined">
        ページを更新する
      </Button>
    </Box>
  );
};
