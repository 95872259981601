import { lighten, darken } from '@material-ui/core/styles';
import { colors } from '@itandi/itandi-bb-ui';

const lightenRate = 0.075;
const darkenRate = 0.15;

export const paletteCommon = {
  common: {
    black: colors['itandi-black'],
    white: colors['itandi-white']
  },
  ITANDIBB_BB: {
    main: colors['product-bb'],
    light: 'rgba(255, 145, 65, 0.8)'
  },
  ITANDIBB_NK: {
    main: colors['product-nk'],
    light: 'rgba(0, 178, 213, 0.8)'
  },
  ITANDIBB_MU: {
    main: colors['product-mu'],
    light: 'rgba(31, 164, 128, 0.8)'
  },
  ITANDIExpressiveAquaDark: {
    main: colors['expressive-aqua-dark']
  },
  ITANDISemanticPositive: {
    main: colors['semantic-positive'],
    light: 'rgba(88, 169, 66, 0.8)'
  },
  ITANDISemanticPositive100: {
    main: colors['semantic-positive100']
  },
  ITANDISemanticNegative: {
    main: colors['semantic-negative'],
    light: 'rgba(244, 67, 54, 0.8)'
  },
  ITANDISemanticNegative100: {
    main: colors['semantic-negative100']
  },
  ITANDISemanticWarning: {
    main: colors['semantic-warning']
  },
  ITANDISemanticWarning100: {
    main: colors['semantic-warning100']
  },
  ITANDISemanticLink: {
    main: colors['semantic-link']
  },
  ItandiGray50: {
    main: colors['gray-50']
  },
  ItandiGray100: {
    main: colors['gray-100']
  },
  ItandiGray200: {
    main: colors['gray-200']
  },
  ItandiGray300: {
    main: colors['gray-300']
  },
  ItandiGray400: {
    main: colors['gray-400']
  },
  ItandiGray500: {
    main: colors['gray-500']
  },
  ItandiGray600: {
    main: colors['gray-600']
  },
  ItandiGray700: {
    main: colors['gray-700']
  },
  ItandiGray800: {
    main: colors['gray-800']
  },
  ItandiGray900: {
    main: colors['gray-900']
  },
  secondary: {
    contrastText: colors['itandi-white'],
    main: colors['product-nk'],
    light: lighten(colors['product-nk'], lightenRate),
    dark: darken(colors['product-nk'], darkenRate)
  },
  success: {
    contrastText: colors['itandi-white'],
    main: colors['semantic-positive'],
    light: lighten(colors['semantic-positive'], lightenRate),
    dark: darken(colors['semantic-positive'], darkenRate)
  },
  info: {
    contrastText: colors['itandi-white'],
    main: colors['expressive-aqua'],
    light: lighten(colors['expressive-aqua'], lightenRate),
    dark: darken(colors['expressive-aqua'], darkenRate)
  },
  warning: {
    contrastText: colors['itandi-white'],
    main: colors['expressive-yellow'],
    light: lighten(colors['expressive-yellow'], lightenRate),
    dark: darken(colors['expressive-yellow'], darkenRate)
  }
};
