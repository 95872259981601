import React from 'react';
import { ErrorBrokerageCompany } from '../../parts/Common/ErrorBrokerageCompany';
import { urlHelper } from '../../../constants/common/url/urlHelpers';

type Props = Readonly<{
  requireManagementCompanyAccount: boolean;
}>;

// 仲介会社：権限エラー画面
export const ForbiddenBrokerageCompanyPage: React.FC<Props> = ({
  requireManagementCompanyAccount
}: Props) => (
  <ErrorBrokerageCompany
    title="403 forbidden"
    subtitle={`こちらのページを見る権限がありません。${
      requireManagementCompanyAccount
        ? '管理会社のアカウントでログインし直してください'
        : ''
    }`}
    buttonAttributes={[{ href: urlHelper.logout(), text: 'ログインページへ' }]}
    isZishaGyoshakan={false}
    activeMenu="rent"
  />
);
