import React from 'react';
import { useTextStyles } from './TextStyle';

type Props = Readonly<{
  children: React.ReactNode;
  small: boolean;
}>;

// 共通/画面説明文
export const ContentsText: React.FC<Props> = ({ children, small }: Props) => {
  const classes = useTextStyles();
  return (
    <span className={small ? classes.contentsSmall : classes.contents}>
      {children}
    </span>
  );
};
