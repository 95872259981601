import React from 'react';
import { Error } from '../../parts/Common/Error';

type Props = Readonly<{
  title: string;
  subtitle: string;
  buttonAttributes: ReadonlyArray<Readonly<{ text: string; href: string }>>;
}>;

// ヘッダーなし：エラー画面
export const ErrorTemplate: React.FC<Props> = ({
  title,
  subtitle,
  buttonAttributes
}: Props) => (
  <Error
    title={title}
    subtitle={subtitle}
    buttonAttributes={buttonAttributes}
  />
);
