import { useState, useEffect } from 'react';
import _ from 'lodash';
import { getNotifications } from '../utils/api/notification';
import { returnNotificationsIds } from '../utils/header';
import { Notification } from '../types/notification';
import { PromiseType } from '../types/promiseType';

export type APIStatusType = PromiseType<ReturnType<typeof getNotifications>>[1];

export const useNotifications = (
  trigger: boolean
): Readonly<[ReadonlyArray<Notification>, number, boolean, APIStatusType]> => {
  const [notifications, setNotifications] = useState<
    ReadonlyArray<Notification>
  >([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loadedAPIStatusType, setLoadedAPIStatusType] =
    useState<APIStatusType>('successful');
  const [notificationsBadgeCount, setNotificationsBadgeCount] =
    useState<number>(0);

  useEffect(() => {
    (async (): Promise<void> => {
      setLoaded(false);
      const [result, status] = await getNotifications();
      setLoadedAPIStatusType(status);
      if (status === 'successful' && !('message' in result)) {
        setNotifications(result);
        const notificationsIds = returnNotificationsIds(result);
        setNotificationsBadgeCount(result?.length);
        const localNotificationsIds = JSON.parse(
          String(localStorage.getItem('notificationsIds'))
        );
        const returnComparisonNotificationsId = (
          notification_id: number
        ): boolean => _.includes(localNotificationsIds, notification_id);
        setNotificationsBadgeCount(
          _.reject(notificationsIds, returnComparisonNotificationsId).length
        );
      }
      setLoaded(true);
    })();
  }, [trigger]);

  return [notifications, notificationsBadgeCount, loaded, loadedAPIStatusType];
};
