import { OptionTypeBase } from 'react-select/src/types';
import { Account } from '../../types/account';
import { BadRequestResponse, client, SuccessfulResponse } from '../client';
import { apiURLPrefix } from './urls';

export const getAccountEdit = async (): Promise<
  SuccessfulResponse<Account>
> => {
  const result = await client.get<Account>(`${apiURLPrefix}/account/edit`);

  if (result[1] !== 'successful') {
    throw new Error('Failed to getAccountEdit');
  }

  return result;
};

type ApiResponseAccountEditType =
  | SuccessfulResponse<Account>
  | BadRequestResponse;

export const putAccount = async (
  params: Readonly<OptionTypeBase>
): Promise<ApiResponseAccountEditType> => {
  const result = await client.putCustomResponse<Account>(
    `${apiURLPrefix}/account`,
    params
  );

  if (result[1] === 'notFound' || result[1] === 'forbidden') {
    throw new Error('Failed to putAccount');
  }

  return result;
};
