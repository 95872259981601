import React from 'react';
import { urlHelper } from '../../constants/common/url/urlHelpers';
import { ErrorTemplate } from '../../components/templates/Error/ErrorTemplate';

export const TooManyRequestsPage: React.FC = () => (
  <ErrorTemplate
    title="429 Too Many Requests"
    subtitle="アクセスが多いため一時的にアクセスできません。時間を置いて再度おためしください"
    buttonAttributes={[{ href: urlHelper.top(), text: 'TOPへ' }]}
  />
);
