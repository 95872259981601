import { useState, useEffect } from 'react';
import {
  CsvExportPromotionAuthentication,
  getCsvExportPromotionAuthentication
} from '../utils/api/csvExportPromotionUsableMaster/authentication';

export const useCsvExportPromotionAuthentication = (): Readonly<
  [CsvExportPromotionAuthentication, boolean]
> => {
  const [
    csvExportPromotionAuthentication,
    setCsvExportPromotionAuthentication
  ] = useState<CsvExportPromotionAuthentication>({
    authenticated: false
  });
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    (async (): Promise<void> => {
      const [result, status] = await getCsvExportPromotionAuthentication();

      if (status === 'successful' && !('message' in result)) {
        setCsvExportPromotionAuthentication(result);
      }
      setLoaded(true);
    })();
  }, []);

  return [csvExportPromotionAuthentication, loaded];
};
