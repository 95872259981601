import React from 'react';
import { useZishaGyoshakanManagementCompanyAuthentication } from '../hooks/useZishaGyoshakanManagementCompanyAuthentication';
import { LoadingPage } from '../components/templates/Loading';
import { ForbiddenBrokerageCompanyPage } from '../components/templates/Error/ForbiddenBrokerageCompanyPage';

type Props = Readonly<{
  children: Readonly<JSX.Element>;
}>;

export const ZishaGyoshakanManagementCompanyRoutes: React.FC<Props> = ({
  children
}) => {
  const [
    zishaGyoshakanManagementCompanyAuthentication,
    loadedZishaGyoshakanManagementCompanyAuthentication
  ] = useZishaGyoshakanManagementCompanyAuthentication();

  if (!loadedZishaGyoshakanManagementCompanyAuthentication) {
    return <LoadingPage />;
  }

  return zishaGyoshakanManagementCompanyAuthentication.authenticated ? (
    children
  ) : (
    <ForbiddenBrokerageCompanyPage requireManagementCompanyAccount={false} />
  );
};
