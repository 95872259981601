import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Logo from '../../../../assets/images/itandibb_rgb_bk.svg';

const useStyles = makeStyles({
  Logo: {
    width: '120px',
    marginLeft: 0
  }
});

export const ITANDIBBLogo: React.FC = () => {
  const classes = useStyles();
  return <img alt="" className={classes.Logo} src={Logo} />;
};
