import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export const LoadingPage: React.FC = () => (
  <div className="LoadingArea">
    <div>
      <CircularProgress disableShrink />
    </div>
  </div>
);
