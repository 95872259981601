import React, { useMemo, createContext, useState } from 'react';
import { PropertySearchModeBasic } from '../types/propertySearchParams/mode';
import { searchFormFieldsListSaleRoomOrBuildingDefault } from '../constants/saleProperty';
import { JgdcCode } from '../types/jgdcCode';
import { useSearchFormJgdcCodes } from '../hooks/useSearchFormJgdcCodes';
import { useBool } from '../hooks/util/useBool';
import { SearchablePropertySort } from '../types/propertySearchParams/sort';

export const SaleBusinessPropertyGlobalContext = createContext(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as {
    // apiへ渡す値系
    saleBusinessPropertySearchSelected: Readonly<
      typeof searchFormFieldsListSaleRoomOrBuildingDefault
    >;
    searchFormJgdcCodes: ReadonlyArray<JgdcCode>;
    setFilteredSearchFormJgdcCodes: (
      jgdcCodes: ReadonlyArray<JgdcCode>
    ) => void;
    setSaleBusinessPropertySearchSelected: React.Dispatch<
      React.SetStateAction<
        Readonly<typeof searchFormFieldsListSaleRoomOrBuildingDefault>
      >
    >;
    saleBusinessPropertyMode: PropertySearchModeBasic;
    setSaleBusinessPropertyMode: React.Dispatch<
      React.SetStateAction<PropertySearchModeBasic>
    >;
    saleBusinessPropertyOffset: number;
    setSaleBusinessPropertyOffset: React.Dispatch<React.SetStateAction<number>>;
    saleBusinessPropertySelectedSort: SearchablePropertySort;
    setSaleBusinessPropertySelectedSort: (sort: SearchablePropertySort) => void;
    // その他
    isSaleBusinessPropertyListDrawerOpened: boolean;
    openSaleBusinessPropertyListDrawer: () => void;
    closeSaleBusinessPropertyListDrawer: () => void;
  }
);

type Props = Readonly<{
  children: React.ReactNode;
}>;

export const SaleBusinessPropertyGlobalContextProvider: React.FC<Props> = ({
  children
}) => {
  const [
    saleBusinessPropertySearchSelected,
    setSaleBusinessPropertySearchSelected
  ] = useState<Readonly<typeof searchFormFieldsListSaleRoomOrBuildingDefault>>(
    searchFormFieldsListSaleRoomOrBuildingDefault
  );
  const [searchFormJgdcCodes, setFilteredSearchFormJgdcCodes] =
    useSearchFormJgdcCodes();
  const [saleBusinessPropertyMode, setSaleBusinessPropertyMode] =
    useState<PropertySearchModeBasic>('aggregate');
  const [saleBusinessPropertyOffset, setSaleBusinessPropertyOffset] =
    useState<number>(1);
  const [
    saleBusinessPropertySelectedSort,
    setSaleBusinessPropertySelectedSort
  ] = useState<SearchablePropertySort>('last_status_opened_at desc');
  const [
    isSaleBusinessPropertyListDrawerOpened,
    openSaleBusinessPropertyListDrawer,
    closeSaleBusinessPropertyListDrawer
  ] = useBool(true);

  const contextValue = useMemo(
    () => ({
      saleBusinessPropertySearchSelected,
      setSaleBusinessPropertySearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      saleBusinessPropertyMode,
      setSaleBusinessPropertyMode,
      saleBusinessPropertyOffset,
      setSaleBusinessPropertyOffset,
      saleBusinessPropertySelectedSort,
      setSaleBusinessPropertySelectedSort,
      isSaleBusinessPropertyListDrawerOpened,
      openSaleBusinessPropertyListDrawer,
      closeSaleBusinessPropertyListDrawer
    }),
    [
      saleBusinessPropertySearchSelected,
      setSaleBusinessPropertySearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      saleBusinessPropertyMode,
      setSaleBusinessPropertyMode,
      saleBusinessPropertyOffset,
      setSaleBusinessPropertyOffset,
      saleBusinessPropertySelectedSort,
      setSaleBusinessPropertySelectedSort,
      isSaleBusinessPropertyListDrawerOpened,
      openSaleBusinessPropertyListDrawer,
      closeSaleBusinessPropertyListDrawer
    ]
  );

  return (
    <SaleBusinessPropertyGlobalContext.Provider value={contextValue}>
      {children}
    </SaleBusinessPropertyGlobalContext.Provider>
  );
};
