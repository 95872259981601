import React from 'react';
import { ItandiHeaderAccountMenuItem } from '@itandi/itandi-bb-ui';
import {
  urlHelper,
  bkUrlHelper
} from '../../../../constants/common/url/urlHelpers';

export const AccountMenu: React.FC = () => (
  <>
    <ItandiHeaderAccountMenuItem url={bkUrlHelper.edit()}>
      アカウント設定
    </ItandiHeaderAccountMenuItem>
    <ItandiHeaderAccountMenuItem url={urlHelper.accountSetting()}>
      図面・見積書設定
    </ItandiHeaderAccountMenuItem>
  </>
);
