import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getLoginInfo } from '../utils/api/session';
import { LoginInfo } from '../types/loginInfo';
import { urlHelper } from '../constants/common/url/urlHelpers';

export const emptyLoginInfo = {
  authenticated: false,
  exist_management_company: false,
  use_create_zumen: false
};

export const useLoginInfoOrRedirect = (): Readonly<[LoginInfo, boolean]> => {
  const [loginInfo, setLoginInfo] = useState<LoginInfo>(emptyLoginInfo);
  const [loaded, setLoaded] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    (async (): Promise<void> => {
      setLoginInfo(await getLoginInfo());
      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    loaded &&
      loginInfo.authenticated === false &&
      history.push(urlHelper.login());
  }, [loaded, loginInfo.authenticated, history]);

  return [loginInfo, loaded];
};
