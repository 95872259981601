import React, { useMemo, createContext, useState } from 'react';
import { searchFormFieldsListSaleLandDefault } from '../constants/saleProperty';
import { SearchablePropertySort } from '../types/propertySearchParams/sort';
import { JgdcCode } from '../types/jgdcCode';
import { useSearchFormJgdcCodes } from '../hooks/useSearchFormJgdcCodes';
import { PropertySearchModeLimited } from '../types/propertySearchParams/mode';
import { useBool } from '../hooks/util/useBool';

export const SaleLandGlobalContext = createContext(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as {
    isSaleLandListDrawerOpened: boolean;
    openSaleLandListDrawer: () => void;
    closeSaleLandListDrawer: () => void;
    saleLandSearchSelected: Readonly<
      typeof searchFormFieldsListSaleLandDefault
    >;
    setSaleLandSearchSelected: (
      selectedSort: typeof searchFormFieldsListSaleLandDefault
    ) => void;
    saleLandMode: PropertySearchModeLimited;
    setSaleLandMode: (mode: PropertySearchModeLimited) => void;
    searchFormJgdcCodes: ReadonlyArray<JgdcCode>;
    setFilteredSearchFormJgdcCodes: (
      jgdcCodes: ReadonlyArray<JgdcCode>
    ) => void;
    saleLandOffset: number;
    setSaleLandOffset: (offset: number) => void;
    saleLandSelectedSort: SearchablePropertySort;
    setSaleLandSelectedSort: (sort: SearchablePropertySort) => void;
  }
);

type Props = Readonly<{
  children: React.ReactNode;
}>;

export const SaleLandGlobalContextProvider: React.FC<Props> = ({
  children
}) => {
  const [
    isSaleLandListDrawerOpened,
    openSaleLandListDrawer,
    closeSaleLandListDrawer
  ] = useBool(true);
  const [saleLandSearchSelected, setSaleLandSearchSelected] = useState<
    Readonly<typeof searchFormFieldsListSaleLandDefault>
  >(searchFormFieldsListSaleLandDefault);
  const [saleLandMode, setSaleLandMode] =
    useState<PropertySearchModeLimited>('single');
  const [searchFormJgdcCodes, setFilteredSearchFormJgdcCodes] =
    useSearchFormJgdcCodes();
  const [saleLandOffset, setSaleLandOffset] = useState<number>(1);
  const [saleLandSelectedSort, setSaleLandSelectedSort] =
    useState<SearchablePropertySort>('last_status_opened_at desc');

  const contextValue = useMemo(
    () => ({
      isSaleLandListDrawerOpened,
      openSaleLandListDrawer,
      closeSaleLandListDrawer,
      saleLandSearchSelected,
      setSaleLandSearchSelected,
      saleLandMode,
      setSaleLandMode,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      saleLandOffset,
      setSaleLandOffset,
      saleLandSelectedSort,
      setSaleLandSelectedSort
    }),
    [
      isSaleLandListDrawerOpened,
      openSaleLandListDrawer,
      closeSaleLandListDrawer,
      saleLandSearchSelected,
      setSaleLandSearchSelected,
      saleLandMode,
      setSaleLandMode,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      saleLandOffset,
      setSaleLandOffset,
      saleLandSelectedSort,
      setSaleLandSelectedSort
    ]
  );

  return (
    <SaleLandGlobalContext.Provider value={contextValue}>
      {children}
    </SaleLandGlobalContext.Provider>
  );
};
