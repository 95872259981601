import React from 'react';
import { urlHelper } from '../../../constants/common/url/urlHelpers';
import { ErrorBrokerageCompany } from '../../parts/Common/ErrorBrokerageCompany';

export const TooManyRequestsBrokerageCompanyPage: React.FC = () => (
  <ErrorBrokerageCompany
    title="429 Too Many Requests"
    subtitle="アクセスが多いため一時的にアクセスできません。時間を置いて再度おためしください"
    buttonAttributes={[{ href: urlHelper.top(), text: 'TOPへ' }]}
    isZishaGyoshakan={false}
    activeMenu="rent"
  />
);
