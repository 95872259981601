/* eslint-disable */

/**
 * LoadingOverlayCustom
 * react-loading-overlayのカスタム版
 * 元ライブラリ:https://github.com/derrickpelletier/react-loading-overlay
 */

import React, { Fragment } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import img from '../../../assets/images/itandibb_rgb_bk.svg';
import { styled, withStyles } from '@material-ui/core/styles';

interface LoadingOverlayCustomProps {
  active: boolean;
  text: string;
  spinner: boolean;
  className: string;
  background: string;
  style: React.CSSProperties;
  relative: boolean;
  period: number;
  isMap?: boolean;
  isMapMobile?: boolean;
  isFixed?: boolean;
}

class LoadingOverlayCustom extends React.Component<LoadingOverlayCustomProps> {
  public static defaultProps: LoadingOverlayCustomProps = {
    active: false,
    text: '',
    spinner: true,
    className: '_loading-overlay',
    background: '#FFF',
    style: {},
    relative: false,
    period: 0,
    isMap: false,
    isMapMobile: false
  };

  private wrapper: HTMLDivElement | null | undefined;
  UNSAFE_componentWillReceiveProps(nextProps: {
    style: React.CSSProperties;
    active: boolean;
  }) {
    let s = nextProps.style;
    if (nextProps.active && (s.overflow || s.overflowY || s.overflowX)) {
      if (this.wrapper) {
        this.wrapper.scrollTop = 0;
      }
    }
  }

  render() {
    const { active, relative } = this.props;

    let loadNode = active && (
      <LoadingOverlay key="the_dimmer" {...this.props} />
    );

    let styles: React.CSSProperties = {
      position: relative ? 'relative' : 'static',
      ...this.props.style
    };
    if (active) {
      if (styles.overflow) styles.overflow = 'hidden';
      if (styles.overflowY) styles.overflowY = 'hidden';
      if (styles.overflowX) styles.overflowX = 'hidden';
    }
    return (
      <div
        ref={(n) => {
          this.wrapper = n;
        }}
        className={this.props.className}
        style={styles}
      >
        {loadNode}
        {this.props.children}
      </div>
    );
  }
}

interface LoadingOverlayProps {
  key?: string;
  text?: string;
  spinner: boolean;
  background: string;
  relative: boolean;
  period: number;
  progress?: boolean;
  firstLoad?: boolean;
  isMap?: boolean;
  isMapMobile?: boolean;
  isFixed?: boolean;
}
interface LoadingOverlayState {
  completed: number;
}

type IsMapMobileProps = { isMapMobile?: boolean };

const Overlay = styled('div')(
  (props: { background: string; isFixed?: boolean }) => ({
    position: props.isFixed ? 'fixed' : 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    background: props.background,
    color: '#fff',
    transition: 'opacity 0ms ease-out',
    display: 'flex',
    textAlign: 'center',
    fontSize: '1.2em',
    zIndex: 990,
    opacity: 0.5,
    '&._loading-overlay-transition-appear, &._loading-overlay-transition-enter':
      {
        opacity: 0.01
      },
    '&._loading-overlay-transition-appear._loading-overlay-transition-appear-active, &._loading-overlay-transition-enter._loading-overlay-transition-enter-active':
      {
        opacity: 1,
        transition: 'opacity 0.5s ease-in'
      },
    '&._loading-overlay-transition-leave': {
      opacity: 1
    },
    '&._loading-overlay-transition-leave._loading-overlay-transition-leave-active':
      {
        opacity: 0,
        transition: 'opacity 0.5s ease-in'
      }
  })
);

const Spinner = styled('div')({
  position: 'fixed',
  margin: '0px auto 10px auto',
  width: '250px',
  maxHeight: '100%',
  '&:before': {
    content: "''",
    display: 'block',
    paddingTop: '100%'
  }
});

const SpinnerRelative = styled('div')({
  position: 'relative',
  margin: '0px auto 10px auto',
  width: '250px',
  maxHeight: '100%',
  '&:before': {
    content: "''",
    display: 'block',
    paddingTop: '100%'
  }
});

const Content = styled('div')((props: { isMap: boolean | undefined }) => ({
  margin: props.isMap ? '0 50%' : '0 auto'
}));

const Icon = styled('div')((props: IsMapMobileProps) => ({
  display: 'block',
  width: '70%',
  height: '70%',
  background: `url(${img}) no-repeat center`,
  backgroundSize: 'contain',
  position: 'absolute',
  top: props.isMapMobile ? '10%' : '40%',
  left: '10%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000,
  animation: 'iconDash 1s linear infinite'
}));

const IconDash = withStyles({
  '@global @keyframes iconDash': {
    '0%': {
      transform: 'rotateY(0deg)'
    },
    '100%': {
      transform: 'rotateY(90deg)'
    }
  }
})((props: IsMapMobileProps) => <Icon isMapMobile={props.isMapMobile} />);

class LoadingOverlay extends React.Component<
  LoadingOverlayProps,
  LoadingOverlayState
> {
  private document: Document;
  private timer: NodeJS.Timeout | undefined;
  constructor(props: LoadingOverlayProps) {
    super(props);
    this.progress = this.progress.bind(this);
    this.state = {
      completed: 0
    };
    this.document = document;
  }
  componentDidMount() {
    const { period } = this.props;
    this.timer = setInterval(this.progress, period);
  }

  componentWillUnmount() {
    this.setState({ completed: 100 });
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  progress() {
    const { completed } = this.state;
    if (completed === 100) {
      if (this.timer) {
        clearInterval(this.timer);
      }
    } else {
      const diff = Math.random() * 10;
      this.setState({ completed: Math.min(completed + diff, 90) });
    }
  }
  render() {
    const { progress, firstLoad, relative } = this.props;
    let isIE = false;
    if (!this.document.queryCommandSupported('insertText')) {
      isIE = true;
    }
    let spinnerNode = null;
    if (this.props.spinner) {
      spinnerNode = (
        <>
          {relative ? (
            <SpinnerRelative>
              <IconDash isMapMobile={this.props.isMapMobile} />
              <CircularProgress
                disableShrink
                style={
                  this.props.isMapMobile
                    ? { position: 'absolute', top: 150, left: 100 }
                    : {}
                }
              />
              {progress ? (
                <Fragment>
                  <LinearProgress
                    variant="determinate"
                    value={this.state.completed}
                    style={
                      isIE && firstLoad
                        ? { marginTop: '-30px' }
                        : { marginTop: '50px' }
                    }
                  />
                  <div style={{ color: 'gray' }}>処理中...</div>
                </Fragment>
              ) : (
                ''
              )}
            </SpinnerRelative>
          ) : (
            <Spinner>
              <IconDash isMapMobile={this.props.isMapMobile} />
              <CircularProgress
                disableShrink
                style={
                  this.props.isMapMobile
                    ? { position: 'absolute', top: 150, left: 100 }
                    : {}
                }
              />
              {progress ? (
                <Fragment>
                  <LinearProgress
                    variant="determinate"
                    value={this.state.completed}
                    style={
                      isIE && firstLoad
                        ? { marginTop: '-30px' }
                        : { marginTop: '50px' }
                    }
                  />
                  <div style={{ color: 'gray' }}>処理中...</div>
                </Fragment>
              ) : (
                ''
              )}
            </Spinner>
          )}
        </>
      );
    }

    let textNode = null;
    if (this.props.text) textNode = <div>{this.props.text}</div>;

    let contentNode = null;
    if (this.props.text || this.props.spinner) {
      contentNode = (
        <Content isMap={this.props.isMap}>
          {spinnerNode}
          {textNode}
        </Content>
      );
    }

    return (
      <Overlay
        background={this.props.background}
        key="dimmer"
        isFixed={this.props.isFixed}
      >
        {contentNode}
      </Overlay>
    );
  }
}

export default LoadingOverlayCustom;
