import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { Routes } from '../routes/Routes';
import { BaseThemeProvider } from './BaseThemeProvider';
import Loadable from './parts/Common/LoadingOverlayCustom';
import '../assets/stylesheets/Common.scss';

const useStyles = makeStyles({
  empty: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto',

    width: '128',
    height: '64'
  }
});

type Props = Readonly<{
  fetchedLogin: boolean;
}>;

export const App: React.FC<Props> = ({ fetchedLogin }: Props) => {
  const classes = useStyles();

  if (!fetchedLogin) {
    return (
      <Box className={classes.empty}>
        <Loadable active spinner style={{ width: '100%', height: '100%' }} />
      </Box>
    );
  }

  return (
    <BaseThemeProvider>
      <CssBaseline />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </BaseThemeProvider>
  );
};
