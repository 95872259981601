import { alpha } from '@material-ui/core/styles';
import { palette } from '../palette';

export const MuiButton = {
  contained: {
    fontWeight: 700,
    borderRadius: 50,
    boxShadow: '0 3px 6px 0 rgba(0,0,0,0.1)',
    color: palette.common.white,
    transition: '.4s',
    fill: palette.common.white,
    '&:hover': {
      color: palette.common.white,
      textDecoration: 'none',
      boxShadow: '0 10px 20px 0 rgba(0,0,0,0.1)',
      transform: 'translateY(-2px)',
      fill: palette.common.white,
      stroke: palette.common.white
    },
    '&:focus': {
      color: palette.common.white,
      textDecoration: 'none',
      boxShadow: '0 10px 20px 0 rgba(0,0,0,0.1)',
      transform: 'translateY(-2px)'
    },
    '&:disabled': {
      backgroundColor: palette.ItandiGray300.main,
      fill: 'rgba(0, 0, 0, 0.26)',
      stroke: 'rgba(0, 0, 0, 0.26)',
      strokeOpacity: 0
    }
  },
  containedPrimary: {
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.light,
      '@media (hover: none)': {
        backgroundColor: palette.primary.light
      }
    },
    '&:focus': {
      backgroundColor: palette.primary.light,
      '@media (hover: none)': {
        backgroundColor: palette.primary.light
      }
    }
  },
  containedSecondary: {
    backgroundColor: palette.secondary.main,
    '&:hover': {
      backgroundColor: palette.secondary.light,
      '@media (hover: none)': {
        backgroundColor: palette.secondary.light
      }
    },
    '&:focus': {
      backgroundColor: palette.secondary.light,
      '@media (hover: none)': {
        backgroundColor: palette.secondary.light
      }
    }
  },
  outlined: {
    borderRadius: 50,
    boxShadow: '0 3px 6px 0 rgba(0,0,0,0.1)',
    transition: '.4s',
    fontWeight: 700
  },
  outlinedSecondary: {
    color: 'rgba(0, 0, 0, 0.50)',
    border: '3px solid rgba(0, 0, 0, 0.23)',
    padding: '7px !important',
    '&:hover': {
      border: '3px solid rgba(0, 0, 0, 0.23)',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      textDecoration: 'none',
      boxShadow: '0 10px 20px 0 rgba(0,0,0,0.1)',
      transform: 'translateY(-2px)'
    },
    '&:focus': {
      border: '3px solid rgba(0, 0, 0, 0.23)',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      textDecoration: 'none',
      boxShadow: '0 10px 20px 0 rgba(0,0,0,0.1)',
      transform: 'translateY(-2px)'
    },
    '&:disabled': {
      border: '3px solid rgba(0, 0, 0, 0.10)'
    }
  },
  text: {
    borderRadius: 50,
    fontWeight: 700
  },
  textSecondary: {
    color: palette.ITANDISemanticNegative.main,
    '&:hover': {
      backgroundColor: alpha(palette.common.black, 0.08)
    },
    '&:focus': {
      backgroundColor: alpha(palette.common.black, 0.08)
    }
  }
};
