import {
  BadRequestResponse,
  client,
  ForbiddenResponse,
  NotFoundResponse
} from '../client';
import { Notification } from '../../types/notification';
import { apiURLPrefix } from './urls';

type Notifications = Readonly<{
  status_code: number;
  notifications: ReadonlyArray<Notification>;
}>;

type ApiGetNotificationsType =
  | Readonly<[ReadonlyArray<Notification>, 'successful']>
  | NotFoundResponse
  | ForbiddenResponse
  | BadRequestResponse;

export const getNotifications = async (): Promise<ApiGetNotificationsType> => {
  const result = await client.get<Notifications>(
    `${apiURLPrefix}/notifications`
  );
  if (result[1] === 'successful') {
    return [result[0].notifications, result[1]];
  }
  return result;
};
