import React from 'react';
import { useCsvExportPromotionAuthentication } from '../hooks/useCsvExportPromotionAuthentication';
import { LoadingPage } from '../components/templates/Loading';
import { ForbiddenBrokerageCompanyPage } from '../components/templates/Error/ForbiddenBrokerageCompanyPage';

type Props = Readonly<{
  children: Readonly<JSX.Element>;
}>;

export const CsvExportPromotionRoutes: React.FC<Props> = ({ children }) => {
  const [
    csvExportPromotionAuthentication,
    loadedCsvExportPromotionAuthentication
  ] = useCsvExportPromotionAuthentication();

  if (!loadedCsvExportPromotionAuthentication) {
    return <LoadingPage />;
  }

  return csvExportPromotionAuthentication.authenticated ? (
    children
  ) : (
    <ForbiddenBrokerageCompanyPage requireManagementCompanyAccount={false} />
  );
};
