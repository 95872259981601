import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from '../theme';

type Props = Readonly<{
  children: ReadonlyArray<Readonly<JSX.Element>>;
}>;

export const BaseThemeProvider: React.FC<Props> = ({
  children
}: Props): JSX.Element => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
);
