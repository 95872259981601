import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { init } from '@sentry/browser';
import { App } from './components/App';
import './assets/stylesheets/application.scss';

if (process.env.NODE_ENV !== 'development') {
  init({
    dsn: 'https://1df28e730fc7426292de0893d6fcb8f2@sentry.io/5172826',
    environment: process.env.NODE_ENV
  });
}

render(
  <CookiesProvider>
    <App fetchedLogin />
  </CookiesProvider>,
  document.getElementById('root')
);
