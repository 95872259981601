import React from 'react';
import {
  ItandiHeaderListItem,
  ItandiHeaderListItemRow
} from '@itandi/itandi-bb-ui';
import { urlHelper } from '../../../../constants/common/url/urlHelpers';

type Props = Readonly<{
  isActive: boolean;
}>;

export const SaleMenu: React.FC<Props> = ({ isActive }: Props) => (
  <ItandiHeaderListItem label="売買物件" active={isActive}>
    <ItandiHeaderListItemRow url={urlHelper.saleRoomsList()}>
      居住用部屋
    </ItandiHeaderListItemRow>
    <ItandiHeaderListItemRow url={urlHelper.saleBusinessPropertiesList()}>
      事業用物件
    </ItandiHeaderListItemRow>
    <ItandiHeaderListItemRow url={urlHelper.saleLandsList()}>
      土地
    </ItandiHeaderListItemRow>
  </ItandiHeaderListItem>
);
