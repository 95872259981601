import { palette } from '../palette';

export const MuiToggleButton = {
  root: {
    padding: 4,
    fontSize: 12,
    backgroundColor: palette.ItandiGray200.main,
    color: palette.common.black,
    border: 'none',
    width: 40,
    '&$selected': {
      backgroundColor: palette.common.white,
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
      color: palette.common.black,
      borderRadius: 4,
      borderTopLeftRadius: '4px !important',
      borderBottomLeftRadius: '4px !important',
      borderTopRightRadius: '4px !important',
      borderBottomRightRadius: '4px !important'
    },
    '&:disabled': {
      backgroundColor: palette.common.white,
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.25)',
      color: palette.common.black,
      borderRadius: 4,
      borderTopLeftRadius: '4px !important',
      borderBottomLeftRadius: '4px !important',
      borderTopRightRadius: '4px !important',
      borderBottomRightRadius: '4px !important'
    }
  }
};
