// 地図マーカー（広域）:アイコン画像
export const MapWideAreaMarkerIcon =
  window.btoa(`<svg width="34px" height="34px" viewBox="0 0 34 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="MapMarker">
            <circle id="Oval" fill="#0080FF" fill-rule="nonzero" opacity="0.7" cx="17" cy="17" r="17"></circle>
            <g id="Marker" transform="translate(3.000000, 3.000000)">
                <rect id="Rectangle-path" fill="#433F4A" fill-rule="nonzero" x="0" y="0" width="28" height="28" rx="14"></rect>
                <rect id="Rectangle-path" stroke="#FFFFFF" stroke-width="3" x="1.5" y="1.5" width="25" height="25" rx="12.5"></rect>
            </g>
        </g>
    </g>
</svg>
  `);
