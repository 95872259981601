import { MapWideAreaMarkerIcon } from '../components/parts/Marker/Icon/MapWideAreaMarkerIcon';
import { MapWideAreaMarkerHoverIcon } from '../components/parts/Marker/Icon/MapWideAreaMarkerHoverIcon';
import { Building as RentRoomBuilding } from '../types/property/resouces/rentRoom';
import { Building as RentBusinessOfferBuilding } from '../types/property/resouces/rentBusiness';
import { MapProperty } from '../types/components/map';

// 建物ごと検索の建物表示件数
export const BuildingListLimit = 20;

export const additionalSearchBucketSize = {
  base: 5,
  additional: 50
};

// すべて表示するボタン押下前の初期表示件数
export const ShowAllCount = 3;

// 地図検索ーマーカークリックアニメーション時間（3秒）
export const MapMarkerAnimationInterval = 3000;

// 地図検索ーズーム値デフォルト
export const MapZoomDefault = 17;

// 地図検索ー最小ズーム値
export const MapMinZoom = 16;

// 地図検索ー検索結果を表示するズーム閾値
export const MapZoomShowingResultsThreshold = 18;

// 地図検索ー最小ズーム値（モバイル）
export const MapMinZoomMobile = 15;

// 地図検索ー検索結果を表示するズーム閾値（モバイル）
export const MapZoomShowingResultsThresholdMobile = 17;

// 地図マーカー（広域）:Icon
export const MapWideAreaMarkerIconRender = (): {
  scaledSize: google.maps.Size;
  url: string;
} => ({
  url: `data:image/svg+xml;base64,${MapWideAreaMarkerIcon}`,
  scaledSize: new google.maps.Size(34, 34)
});

// 地図マーカー（広域）:Icon(hover）
export const MapWideAreaMarkerHoverIconRender = (): {
  scaledSize: google.maps.Size;
  anchor: google.maps.Point;
  url: string;
} => ({
  url: `data:image/svg+xml;base64,${MapWideAreaMarkerHoverIcon}`,
  scaledSize: new google.maps.Size(80, 80),
  anchor: new google.maps.Point(40, 57)
});

// 地図マーカー（広域）:Label
export const MapWideAreaMarkerLabelRender = (
  mapMarkerText: string
): {
  color: string;
  fontSize: string;
  className: string;
  text: string;
  fontWeight: string;
} => ({
  text: mapMarkerText,
  color: 'rgba(255,255,255,0.9)',
  fontSize: '11px',
  fontWeight: '700',
  className: 'MapWideAreaMarkerIcon'
});

export const buildMapProperties = (
  items: ReadonlyArray<RentRoomBuilding | RentBusinessOfferBuilding>
): ReadonlyArray<MapProperty> =>
  items.map((item: RentRoomBuilding | RentBusinessOfferBuilding) => {
    if ('rooms' in item) {
      return {
        propertyId: item.building_property_id,
        coordinate: item.coordinate,
        itemsLength: item.rooms?.length,
        moreItemsExist: item.more_rooms_exist || false,
        mapMarkerText: item.more_rooms_exist
          ? `${additionalSearchBucketSize.base}+`
          : item.rooms.length.toString()
      };
    }
    return {
      propertyId: item.building_property_id,
      coordinate: item.coordinate,
      itemsLength: item.offers?.length,
      moreItemsExist: item.more_offers_exist || false,
      mapMarkerText: item.more_offers_exist
        ? `${additionalSearchBucketSize.base}+`
        : item.offers.length.toString()
    };
  });
