export const renderErrorMsg = {
  search_message:
    '正しく入力されていない項目があります。メッセージをご確認の上、もう一度ご入力ください',
  search_sub_message: 'メッセージをクリックすると該当項目に遷移します',
  number: '半角数字で入力してください',
  integer: '半角の整数で入力してください',
  real_number: '半角数字（マイナス可）で入力してください',
  number_integer: '半角の整数または小数で入力してください',
  not_integer: '数字を含めないでください',
  phone_number: '正しい形式の電話番号（ハイフンなし）で入力してください',
  phone_number_hyphen: '正しい形式の電話番号（ハイフンあり）で入力してください',
  email: '正しい形式のメールアドレスで入力してください',
  required_input: '入力してください',
  required_select: '選択してください',
  required_input_or_select: '入力または選択してください',
  min_select: '内見時間は最低1つ選択してください',
  select_time_before: '選択できる内見時間は',
  select_time_after: 'つまでです',
  select_times_error: '内見時間は連続して選んでください',
  text_reason_error:
    '「キャンセル理由」が「その他」の場合はキャンセル理由を入力してください',
  text_max_input: '文字以内で入力して下さい',
  set_station_walk_minute: '指定する場合は「路線・駅」も指定してください',
  image_type_error: ' jpeg, png, gif以外のファイル形式はアップロードできません',
  day_format: '正しい日付を入力してください',
  custom_options_max: '個以内で作成してください',
  search_mode_map_switch:
    '「地図検索」では「市区町村」または「町域・丁目」を1つ以上選択してください',
  search_mode_map_area_select:
    '「地図検索」では「市区町村」を1つだけ選択してください'
};

export const errorMsg = {
  ms500: '500 internal server error',
  sub500: 'システム管理者へご連絡下さい',
  ms404: '404 Page not found',
  sub404: 'お探しのページは見つかりませんでした',
  ms_fail_booking: '内見予約できませんでした',
  ms_fail_cancel: '内見予約をキャンセルできませんでした',
  ms_fail_download: '図面のダウンロードができませんでした',
  ms_fail_paper_download: '紙申込書のダウンロードができませんでした',
  following: '管理会社のフォローができませんでした',
  follow_destroy: '管理会社のフォローを解除することができませんでした',
  fail_estimate_pdf_create: '見積書の作成ができませんでした',
  not_use_estimate_pdf_create: '見積書作成機能が使えません',
  not_get_estimate_room_info: '物件情報が取得できませんでした',
  fail_create: '保存できませんでした',
  fail_update: '更新できませんでした',
  fail_delete: '削除できませんでした'
};

export const defaultMsg = {
  image_exists: '図面をダウンロードできます',
  image_not_exist: '図面がありません',
  image_downloaded: '図面のダウンロードが完了しました',
  image_undownloadable: '帯替え機能が有効なため一斉ダウンロードは使えません',
  success_booking: '内見予約が完了しました',
  success_cancel: '内見予約をキャンセルしました',
  paper_downloaded: '紙申込書のダウンロードが完了しました',
  list_no_result: 'ご希望の条件に一致する検索結果がありませんでした',
  list_default:
    '画面左側の検索フォームにご希望の条件を入力し、検索を行うことができます',
  list_default_rent:
    '画面左側の検索フォームにご希望の条件を入力し、賃貸物件の検索を行うことができます',
  list_default_sale:
    '画面左側の検索フォームにご希望の条件を入力し、売買物件の検索を行うことができます',
  list_too_many_result:
    '検索結果数が多すぎます。ご希望の条件を増やしてもう一度検索してください',
  list_params_is_empty: '検索条件を指定してください',
  reload_notice:
    '画面を更新されますと、検索条件がクリアされます。更新されますか？',
  account_edit: '図面用帯情報が更新されました',
  account_edit_error: '図面用帯情報が更新できませんでした',
  pdf_obi_info_is_not_set_error: '帯情報が未入力です',
  pdf_cannot_use_creating_zumen_room_error: '図面が作成できない部屋です',
  pdf_edit_error: '図面が作成できませんでした',
  bad_request_error: '検索条件を正しく指定してください',
  following: 'のフォローをしました',
  follow_destroy: 'のフォローを解除しました',
  follow_non: '左の一覧からフォローしたい管理会社を選択してください',
  follow_list: 'フォローできる管理会社がありません',
  follow_no_new_list: 'フォロー中の管理会社の新着物件がありません',
  success_create: '保存しました',
  success_update: '更新しました',
  success_delete: '削除しました',
  non_image: '画像情報なし',
  not_follow_company: '現在フォロー中の企業はありません',
  not_map_loading: '地図の読み込みができません',
  auto_promotion_update: '配信設定が更新されました',
  auto_promotion_update_error: '配信設定が更新できませんでした',
  auto_promotion_create: '配信設定が作成されました',
  auto_promotion_create_error: '配信設定が作成されました'
};

export const helpMsg = {
  image_download:
    '図面をダウンロードしたい物件のチェックボックスにチェックを入れ、「図面のダウンロード」ボタンをクリックすると一括で図面のダウンロードを行うことができます。',
  pdf_download_obi_type:
    '管理会社様の設定、または、登録されている物件の情報によって選択可能な帯の種類が制限される場合があります。'
};
