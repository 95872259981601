import React from 'react';
import { ErrorTemplate } from '../../components/templates/Error/ErrorTemplate';
import { urlHelper } from '../../constants/common/url/urlHelpers';

// ヘッダーなし：権限エラー画面
export const ForbiddenPage: React.FC = () => (
  <ErrorTemplate
    title="403 forbidden"
    subtitle="こちらのページを見る権限がありません。ログインし直してください"
    buttonAttributes={[{ href: urlHelper.logout(), text: 'ログインページへ' }]}
  />
);
