import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useZishaGyoshakanBrokerageCompanyAuthentication } from '../hooks/useZishaGyoshakanBrokerageCompanyAuthentication';
import { LoadingPage } from '../components/templates/Loading';
import { NotFoundZishaGyoshakanBrokerageCompanyPage } from '../components/templates/Error/NotFoundZishaGyoshakanBrokerageCompanyPage';
import { ForbiddenZishaGyoshakanBrokerageCompanyPage } from '../components/templates/Error/ForbiddenZishaGyoshakanBrokerageCompanyPage';
import { ZishaGyoshakanParams } from '../types/zishaGyoshakan';

type Props = Readonly<{
  children: Readonly<JSX.Element>;
}>;

export const ZishaGyoshakanBrokerageCompanyRoutes: React.FC<Props> = ({
  children
}) => {
  const match = useRouteMatch<ZishaGyoshakanParams>();

  const [
    zishaGyoshakanBrokerageCompanyAuthentication,
    loaded,
    loadedAPIStatusType
  ] = useZishaGyoshakanBrokerageCompanyAuthentication(
    match.params.zisha_gyoshakan_master_key
  );

  if (!loaded) {
    return <LoadingPage />;
  }

  if (loadedAPIStatusType === 'notFound') {
    return (
      <NotFoundZishaGyoshakanBrokerageCompanyPage
        zishaGyoshakanKey={match.params.zisha_gyoshakan_master_key}
      />
    );
  }

  return zishaGyoshakanBrokerageCompanyAuthentication.authenticated ? (
    children
  ) : (
    <ForbiddenZishaGyoshakanBrokerageCompanyPage />
  );
};
