export const searchFormFieldsListSaleLandDefault = {
  'address:in': [],
  selectStations: [],
  selectManagementCompanies: [],
  'price:gteq': '',
  'price:lteq': '',
  'station_walk_minutes:lteq': '',
  nearbyTrainStationWithBusMinutesCheck: '',
  'land_area_amount:gteq': '',
  'land_area_amount:lteq': '',
  'offer_deal_type:in': [],
  'land_optimal_usage_type:eq': '',
  'land_yoto_chiiki_type:in': [],
  'land_optimal_usage_type:in': [],
  'option_id:all_in': []
};

export const searchFormFieldsListSaleRoomOrBuildingDefault = {
  'address:in': [],
  selectStations: [],
  'building_name:match': '',
  'room_number:match': '',
  selectManagementCompanies: [],
  'price:gteq': '',
  'price:lteq': '',
  'building_detail_type:in': [],
  'layout:in': [],
  'structure_type:in': [],
  'floor:eq': '',
  'floor:gteq': '',
  'station_walk_minutes:lteq': '',
  nearbyTrainStationWithBusMinutesCheck: '',
  'floor_area_amount:gteq': '',
  'floor_area_amount:lteq': '',
  'offer_deal_type:in': [],
  'building_age:lteq': '',
  'option_id:all_in': [],
  'parking_exists:eq': '',
  'floor_height:gteq': '',
  'main_lighting_direction_type:in': []
};

export const checkBoxListSaleOfferDealType = {
  'offer_deal_type:in': {
    seller: '売主',
    placeholder: '代理',
    strict: '専属専任媒介',
    exclusive: '専任媒介',
    agent: '一般媒介'
  }
};
