import { palette } from '../palette';

export const MuiToggleButtonGroup = {
  root: {
    padding: '2px !important',
    fontSize: 12,
    color: palette.common.black,
    backgroundColor: palette.ItandiGray200.main,
    boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.1)',
    height: 28
  }
};
