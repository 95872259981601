import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Logo from '../../../../assets/images/itandibbplus_logo.svg';

const useStyles = makeStyles({
  Logo: {
    width: '120px',
    height: '30px',
    display: 'block',
    verticalAlign: 'top'
  }
});

export const ITANDIBBPlusLogo: React.FC = () => {
  const classes = useStyles();
  return <img alt="" className={classes.Logo} src={Logo} />;
};
