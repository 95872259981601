import React, { useMemo, createContext, useState } from 'react';
import { PropertySearchModeFullAccess } from '../types/propertySearchParams/mode';
import { MapZoomDefault } from '../constants/propertyList';
import { searchFormFieldsListFolloweeRent } from '../constants/followee';
import { AddressesCoordinateValue } from '../types/propertySearchParams/addressesCoordinate';
import { JgdcCode } from '../types/jgdcCode';
import { useBool } from '../hooks/util/useBool';
import { SearchablePropertySort } from '../types/propertySearchParams/sort';

export const FolloweeRentRoomGlobalContext = createContext(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as {
    // apiへ渡す値系
    followeeRentRoomSearchSelected: Readonly<
      typeof searchFormFieldsListFolloweeRent
    >;
    setFolloweeRentRoomSearchSelected: React.Dispatch<
      React.SetStateAction<Readonly<typeof searchFormFieldsListFolloweeRent>>
    >;
    searchFormJgdcCodes: ReadonlyArray<JgdcCode>;
    setFilteredSearchFormJgdcCodes: React.Dispatch<
      React.SetStateAction<ReadonlyArray<JgdcCode>>
    >;
    followeeRentRoomMode: PropertySearchModeFullAccess;
    setFolloweeRentRoomMode: React.Dispatch<
      React.SetStateAction<PropertySearchModeFullAccess>
    >;
    followeeRentRoomOffset: number;
    setFolloweeRentRoomOffset: React.Dispatch<React.SetStateAction<number>>;
    followeeRentRoomSelectedSort: SearchablePropertySort;
    setFolloweeRentRoomSelectedSort: (sort: SearchablePropertySort) => void;
    // その他
    isFolloweeRentRoomListDrawerOpened: boolean;
    openFolloweeRentRoomListDrawer: () => void;
    closeFolloweeRentRoomListDrawer: () => void;
    // 地図検索周り
    followeeRentRoomAddressesCoordinate: AddressesCoordinateValue;
    setFolloweeRentRoomAddressesCoordinate: React.Dispatch<
      React.SetStateAction<AddressesCoordinateValue>
    >;
    followeeRentRoomMapZoom: number;
    setFolloweeRentRoomMapZoom: React.Dispatch<React.SetStateAction<number>>;
  }
);

type Props = Readonly<{
  children: React.ReactNode;
}>;

// フォロー中企業新着物件（賃貸）
export const FolloweeRentRoomGlobalContextProvider: React.FC<Props> = ({
  children
}) => {
  const [followeeRentRoomSearchSelected, setFolloweeRentRoomSearchSelected] =
    useState<Readonly<typeof searchFormFieldsListFolloweeRent>>(
      searchFormFieldsListFolloweeRent
    );
  const [searchFormJgdcCodes, setFilteredSearchFormJgdcCodes] = useState<
    ReadonlyArray<JgdcCode>
  >([]);
  const [followeeRentRoomMode, setFolloweeRentRoomMode] =
    useState<PropertySearchModeFullAccess>('aggregate');
  const [followeeRentRoomOffset, setFolloweeRentRoomOffset] =
    useState<number>(1);
  const [followeeRentRoomSelectedSort, setFolloweeRentRoomSelectedSort] =
    useState<SearchablePropertySort>('last_status_opened_at desc');
  const [
    isFolloweeRentRoomListDrawerOpened,
    openFolloweeRentRoomListDrawer,
    closeFolloweeRentRoomListDrawer
  ] = useBool(true);
  const [
    followeeRentRoomAddressesCoordinate,
    setFolloweeRentRoomAddressesCoordinate
  ] = useState<AddressesCoordinateValue>({ formSelected: {} });
  const [followeeRentRoomMapZoom, setFolloweeRentRoomMapZoom] =
    useState<number>(MapZoomDefault);

  const contextValue = useMemo(
    () => ({
      followeeRentRoomSearchSelected,
      setFolloweeRentRoomSearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      followeeRentRoomMode,
      setFolloweeRentRoomMode,
      followeeRentRoomOffset,
      setFolloweeRentRoomOffset,
      followeeRentRoomSelectedSort,
      setFolloweeRentRoomSelectedSort,
      isFolloweeRentRoomListDrawerOpened,
      openFolloweeRentRoomListDrawer,
      closeFolloweeRentRoomListDrawer,
      followeeRentRoomAddressesCoordinate,
      setFolloweeRentRoomAddressesCoordinate,
      followeeRentRoomMapZoom,
      setFolloweeRentRoomMapZoom
    }),
    [
      followeeRentRoomSearchSelected,
      setFolloweeRentRoomSearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      followeeRentRoomMode,
      setFolloweeRentRoomMode,
      followeeRentRoomOffset,
      setFolloweeRentRoomOffset,
      followeeRentRoomSelectedSort,
      setFolloweeRentRoomSelectedSort,
      isFolloweeRentRoomListDrawerOpened,
      openFolloweeRentRoomListDrawer,
      closeFolloweeRentRoomListDrawer,
      followeeRentRoomAddressesCoordinate,
      setFolloweeRentRoomAddressesCoordinate,
      followeeRentRoomMapZoom,
      setFolloweeRentRoomMapZoom
    ]
  );

  return (
    <FolloweeRentRoomGlobalContext.Provider value={contextValue}>
      {children}
    </FolloweeRentRoomGlobalContext.Provider>
  );
};
