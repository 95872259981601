import React, { useMemo, createContext, useState } from 'react';
import { HeaderProps } from '../types/headerProps';

type HeaderContextType = Readonly<{
  headerPropsLoaded: boolean;
  setHeaderPropsLoaded:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
  headerProps: HeaderProps;
  setHeaderProps: React.Dispatch<React.SetStateAction<HeaderProps>> | undefined;
}>;

const defaultHeaderProps: HeaderContextType = {
  headerPropsLoaded: false,
  setHeaderPropsLoaded: undefined,
  headerProps: {
    is_zennichi: false,
    using_applications: [],
    product_menus: [],
    company_name: '',
    store_name: '',
    zisha_gyoshakan_master_key: 'itandi',
    csv_export_promotion_usable: false
  },
  setHeaderProps: undefined
};

export const HeaderContext = createContext(defaultHeaderProps);

export const HeaderContextProvider: React.FC<
  Readonly<{ children: React.ReactNode }>
> = ({ children }) => {
  const [headerPropsLoaded, setHeaderPropsLoaded] = useState<boolean>(false);
  const [headerProps, setHeaderProps] = useState<HeaderProps>(
    defaultHeaderProps.headerProps
  );

  const contextValue = useMemo(
    () => ({
      headerPropsLoaded,
      setHeaderPropsLoaded,
      headerProps,
      setHeaderProps
    }),
    [headerPropsLoaded, setHeaderPropsLoaded, headerProps, setHeaderProps]
  );

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>
  );
};
