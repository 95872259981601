import { ApiGetHeaderType, HeaderProps } from '../../types/headerProps';
import { badRequestResponse, client } from '../client';
import { apiURLPrefix } from './urls';

export const getHeader = async (): Promise<ApiGetHeaderType> => {
  const result = await client.get<HeaderProps>(`${apiURLPrefix}/header`);
  if (result[1] === 'forbidden' || result[1] === 'notFound') {
    return badRequestResponse;
  }
  return result;
};
