import { palette } from '../palette';

export const MuiAlert = {
  root: {
    color: `${palette.ItandiGray800.main} !important`,
    fontWeight: 700,
    paddingTop: 10,
    backgroundColor: palette.common.white,
    minWidth: 360,
    boxShadow: '0 1px 3px -1px rgba(0, 0, 0, 0.3)',
    borderRadius: 0
  },
  outlinedInfo: {
    backgroundColor: palette.common.white,
    border: 'initial',
    borderLeft: `2px solid ${palette.info.main}`
  },
  outlinedSuccess: {
    backgroundColor: palette.common.white,
    border: 'initial',
    borderLeft: `2px solid ${palette.success.main}`
  },
  outlinedError: {
    backgroundColor: palette.common.white,
    border: 'initial',
    borderLeft: `2px solid ${palette.ITANDISemanticNegative.main}`
  },
  outlinedWarning: {
    backgroundColor: palette.common.white,
    border: 'initial',
    borderLeft: `2px solid ${palette.warning.main}`
  }
};
