import React, { useMemo, createContext, useState } from 'react';
import { searchFormFieldsListRentRoomOrBuildingDefault } from '../constants/rentProperty';
import { PropertySearchModeFullAccess } from '../types/propertySearchParams/mode';
import { MapZoomDefault } from '../constants/propertyList';
import { AddressesCoordinateValue } from '../types/propertySearchParams/addressesCoordinate';
import { JgdcCode } from '../types/jgdcCode';
import { useSearchFormJgdcCodes } from '../hooks/useSearchFormJgdcCodes';
import { useBool } from '../hooks/util/useBool';
import { SearchablePropertySort } from '../types/propertySearchParams/sort';

export const RentBusinessPropertyGlobalContext = createContext(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as {
    // apiへ渡す値系
    rentBusinessPropertySearchSelected: Readonly<
      typeof searchFormFieldsListRentRoomOrBuildingDefault
    >;
    setRentBusinessPropertySearchSelected: React.Dispatch<
      React.SetStateAction<
        Readonly<typeof searchFormFieldsListRentRoomOrBuildingDefault>
      >
    >;
    searchFormJgdcCodes: ReadonlyArray<JgdcCode>;
    setFilteredSearchFormJgdcCodes: (
      jgdcCodes: ReadonlyArray<JgdcCode>
    ) => void;
    rentBusinessPropertyMode: PropertySearchModeFullAccess;
    setRentBusinessPropertyMode: React.Dispatch<
      React.SetStateAction<PropertySearchModeFullAccess>
    >;
    rentBusinessPropertyOffset: number;
    setRentBusinessPropertyOffset: React.Dispatch<React.SetStateAction<number>>;
    rentBusinessPropertySelectedSort: SearchablePropertySort;
    setRentBusinessPropertySelectedSort: (sort: SearchablePropertySort) => void;
    // その他
    isRentBusinessPropertyListDrawerOpened: boolean;
    openRentBusinessPropertyListDrawer: () => void;
    closeRentBusinessPropertyListDrawer: () => void;
    // 地図検索周り
    rentBusinessPropertyAddressesCoordinate: AddressesCoordinateValue;
    setRentBusinessPropertyAddressesCoordinate: React.Dispatch<
      React.SetStateAction<AddressesCoordinateValue>
    >;
    rentBusinessPropertyMapZoom: number;
    setRentBusinessPropertyMapZoom: React.Dispatch<
      React.SetStateAction<number>
    >;
  }
);

type Props = Readonly<{
  children: React.ReactNode;
}>;

// 賃貸部屋検索
export const RentBusinessPropertyGlobalContextProvider: React.FC<Props> = ({
  children
}) => {
  const [
    rentBusinessPropertySearchSelected,
    setRentBusinessPropertySearchSelected
  ] = useState<Readonly<typeof searchFormFieldsListRentRoomOrBuildingDefault>>(
    searchFormFieldsListRentRoomOrBuildingDefault
  );
  const [searchFormJgdcCodes, setFilteredSearchFormJgdcCodes] =
    useSearchFormJgdcCodes();
  const [rentBusinessPropertyMode, setRentBusinessPropertyMode] =
    useState<PropertySearchModeFullAccess>('aggregate');
  const [rentBusinessPropertyOffset, setRentBusinessPropertyOffset] =
    useState<number>(1);
  const [
    rentBusinessPropertySelectedSort,
    setRentBusinessPropertySelectedSort
  ] = useState<SearchablePropertySort>('last_status_opened_at desc');
  const [
    isRentBusinessPropertyListDrawerOpened,
    openRentBusinessPropertyListDrawer,
    closeRentBusinessPropertyListDrawer
  ] = useBool(true);
  const [
    rentBusinessPropertyAddressesCoordinate,
    setRentBusinessPropertyAddressesCoordinate
  ] = useState<AddressesCoordinateValue>({ formSelected: {} });
  const [rentBusinessPropertyMapZoom, setRentBusinessPropertyMapZoom] =
    useState<number>(MapZoomDefault);

  const contextValue = useMemo(
    () => ({
      rentBusinessPropertySearchSelected,
      setRentBusinessPropertySearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      rentBusinessPropertyMode,
      setRentBusinessPropertyMode,
      rentBusinessPropertyOffset,
      setRentBusinessPropertyOffset,
      rentBusinessPropertySelectedSort,
      setRentBusinessPropertySelectedSort,
      isRentBusinessPropertyListDrawerOpened,
      openRentBusinessPropertyListDrawer,
      closeRentBusinessPropertyListDrawer,
      rentBusinessPropertyAddressesCoordinate,
      setRentBusinessPropertyAddressesCoordinate,
      rentBusinessPropertyMapZoom,
      setRentBusinessPropertyMapZoom
    }),
    [
      rentBusinessPropertySearchSelected,
      setRentBusinessPropertySearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      rentBusinessPropertyMode,
      setRentBusinessPropertyMode,
      rentBusinessPropertyOffset,
      setRentBusinessPropertyOffset,
      rentBusinessPropertySelectedSort,
      setRentBusinessPropertySelectedSort,
      isRentBusinessPropertyListDrawerOpened,
      openRentBusinessPropertyListDrawer,
      closeRentBusinessPropertyListDrawer,
      rentBusinessPropertyAddressesCoordinate,
      setRentBusinessPropertyAddressesCoordinate,
      rentBusinessPropertyMapZoom,
      setRentBusinessPropertyMapZoom
    ]
  );

  return (
    <RentBusinessPropertyGlobalContext.Provider value={contextValue}>
      {children}
    </RentBusinessPropertyGlobalContext.Provider>
  );
};
