import React from 'react';
import {
  ItandiHeaderListItem,
  ItandiHeaderListItemRow
} from '@itandi/itandi-bb-ui';
import { urlHelper } from '../../../../constants/common/url/urlHelpers';

type Props = Readonly<{
  isActive: boolean;
}>;

export const FollowMenu: React.FC<Props> = ({ isActive }: Props) => (
  <ItandiHeaderListItem label="フォロー企業" active={isActive}>
    <ItandiHeaderListItemRow url={urlHelper.followeeRentRoomsList()}>
      賃貸居住用の新着
    </ItandiHeaderListItemRow>
    <ItandiHeaderListItemRow url={urlHelper.followSetting()}>
      フォロー企業の管理
    </ItandiHeaderListItemRow>
  </ItandiHeaderListItem>
);
