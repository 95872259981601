import { palette } from '../palette';

export const MuiBadge = {
  anchorOriginTopRightCircular: {
    zIndex: 99,
    top: 0,
    right: 0,
    color: palette.common.white
  }
};
