export const MuiFormHelperText = {
  root: {
    '&$error': {
      /*
      position: 'absolute',
      top: 45,
       */
      fontWeight: 700,
      bottom: 0,
      whiteSpace: 'nowrap'
    }
  },
  contained: {
    marginLeft: 0,
    marginRight: 0
  }
};
