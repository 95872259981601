import { lighten, darken } from '@material-ui/core/styles';
import { colors } from '@itandi/itandi-bb-ui';
import { paletteCommon } from './paletteCommon';

const lightenRate = 0.075;
const darkenRate = 0.15;

export const palette = {
  primary: {
    contrastText: colors['itandi-white'],
    main: colors['product-bb'],
    light: lighten(colors['product-bb'], lightenRate),
    dark: darken(colors['product-bb'], darkenRate)
  },
  ...paletteCommon
};
