// 地図マーカー（広域）:hoverアイコン画像
export const MapWideAreaMarkerHoverIcon =
  window.btoa(`<svg width="80px" height="80px" viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="MapMarker">
            <rect id="Rectangle" fill="#0080FF" fill-rule="nonzero" opacity="0.401276507" x="0" y="0" width="80" height="80" rx="4"></rect>
            <circle id="Oval" fill="#0080FF" fill-rule="nonzero" opacity="0.7" cx="40" cy="40" r="17"></circle>
            <g id="Marker" transform="translate(26.000000, 26.000000)">
                <rect id="Rectangle-path" fill="#433F4A" fill-rule="nonzero" x="0" y="0" width="28" height="28" rx="14"></rect>
                <rect id="Rectangle-path" stroke="#FFFFFF" stroke-width="3" x="1.5" y="1.5" width="25" height="25" rx="12.5"></rect>
            </g>
        </g>
    </g>
</svg>
  `);
