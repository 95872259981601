import React from 'react';
import clsx from 'clsx';
import { useLabelStyles } from './LabelStyle';

type Props = Readonly<{
  children: React.ReactNode;
  before?: boolean;
  icon?: string;
  svg?: string;
  noLabel?: boolean;
  mark?: boolean;
  booking?: boolean;
  small?: boolean;
}>;

// 共通/項目ラベル
export const CategoryLabel: React.FC<Props> = ({
  children,
  before,
  icon,
  svg,
  noLabel,
  mark,
  booking,
  small
}: Props) => {
  const classes = useLabelStyles();

  return (
    <span
      className={
        /* eslint-disable no-nested-ternary */
        noLabel
          ? ''
          : small
            ? clsx(classes.label, classes.labelSmall)
            : clsx(classes.label, classes.labelNormal)
      }
      style={
        noLabel
          ? undefined
          : booking
            ? { borderLeft: `solid 5px #00b2d5` }
            : { borderLeft: `solid 5px #ff9141` }
      }
    >
      {before && <div className={classes.before} />}
      {svg ? (
        <span
          className="material-icons-sharp"
          style={{ fontSize: 18, marginTop: 3 }}
        >
          {svg}
        </span>
      ) : (
        <i className={icon} />
      )}
      <span className={classes.space} />
      <span className={classes.size}>{children}</span>
      {mark ? <span className={small ? 'Mark Small' : 'Mark'}>必須</span> : ''}
    </span>
  );
};
