import { ApiResponseBodyType, client } from '../../client';
import { apiURLPrefix } from '../urls';

export type CsvExportPromotionAuthentication = Readonly<{
  authenticated: boolean;
}>;

export const getCsvExportPromotionAuthentication = async (): Promise<
  ApiResponseBodyType<CsvExportPromotionAuthentication>
> =>
  client.get<CsvExportPromotionAuthentication>(
    `${apiURLPrefix}/csv_export_promotion_usable_master/authentication`
  );
