import { palette } from '../palette';

export const MuiTextField = {
  root: {
    '& label.Mui-focused': {
      color: palette.primary.main
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: palette.primary.main
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '& fieldset': {
        borderWidth: '2px',
        borderColor: 'rgba(0, 0, 0, 0.54)'
      },
      '&:hover fieldset': {
        borderColor: palette.primary.main
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary.main
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.primary.main
    }
  }
};
