import { useState, useEffect } from 'react';
import {
  getZishaGyoshakanBrokerageCompanyAuthentication,
  ZishaGyoshakanAuthentication
} from '../utils/api/zishaGyoshakanMasters/authentication';
import { PromiseType } from '../types/promiseType';
import { getRoomViewingDigests } from '../utils/api/roomViewingDigests';

export type APIStatusType = PromiseType<
  ReturnType<typeof getRoomViewingDigests>
>[1];

export const useZishaGyoshakanBrokerageCompanyAuthentication = (
  zishaGyoshakanMasterKey: string
): Readonly<[ZishaGyoshakanAuthentication, boolean, APIStatusType]> => {
  const [
    zishaGyoshakanBrokerageCompanyAuthentication,
    setZishaGyoshakanBrokerageCompanyAuthentication
  ] = useState<ZishaGyoshakanAuthentication>({
    authenticated: false
  });
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loadedAPIStatusType, setLoadedAPIStatusType] =
    useState<APIStatusType>('successful');

  useEffect(() => {
    (async (): Promise<void> => {
      const [result, status] =
        await getZishaGyoshakanBrokerageCompanyAuthentication(
          zishaGyoshakanMasterKey
        );

      setLoadedAPIStatusType(status);
      if (status === 'successful' && !('message' in result)) {
        setZishaGyoshakanBrokerageCompanyAuthentication(result);
      }
      setLoaded(true);
    })();
  }, [zishaGyoshakanMasterKey]);

  return [
    zishaGyoshakanBrokerageCompanyAuthentication,
    loaded,
    loadedAPIStatusType
  ];
};
