import { MuiButton } from '../overrides/MuiButton';
import { MuiFormLabel } from '../overrides/MuiFormLabel';
import { MuiInput } from '../overrides/MuiInput';
import { MuiFormHelperText } from '../overrides/MuiFormHelperText';
import { MuiPaper } from '../overrides/MuiPaper';
import { MuiTabs } from '../overrides/MuiTabs';
import { MuiMenuItem } from '../overrides/MuiMenuItem';
import { MuiTableCell } from '../overrides/MuiTableCell';
import { MuiFab } from '../overrides/MuiFab';
import { MuiTextField } from './MuiTextField';
import { MuiSelect } from '../overrides/MuiSelect';
import { MuiOutlinedInput } from '../overrides/MuiOutlinedInput';
import { MuiChip } from '../overrides/MuiChip';
import { MuiAlert } from '../overrides/MuiAlert';
import { MuiBadge } from '../overrides/MuiBadge';
import { MuiFormControl } from '../overrides/MuiFormControl';
import { MuiIconButton } from '../overrides/MuiIconButton';
import { MuiFlatPageButton } from '../overrides/MuiFlatPageButton';
import { MuiToggleButton } from '../overrides/MuiToggleButton';
import { MuiToggleButtonGroup } from '../overrides/MuiToggleButtonGroup';

export const overrides = {
  MuiButton,
  MuiFormLabel,
  MuiInput,
  MuiFormHelperText,
  MuiPaper,
  MuiTabs,
  MuiMenuItem,
  MuiTableCell,
  MuiFab,
  MuiTextField,
  MuiSelect,
  MuiOutlinedInput,
  MuiChip,
  MuiAlert,
  MuiBadge,
  MuiFormControl,
  MuiIconButton,
  MuiFlatPageButton,
  MuiToggleButton,
  MuiToggleButtonGroup
};
