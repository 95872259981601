import { ApiResponseBodyType, client } from '../../client';
import { ZishaGyoshakanContact } from '../../../types/zishaGyoshakanContact';
import { apiURLPrefix } from '../urls';

export const getZishaGyoshakanBrokerageCompanyContact = async (
  zishaGyoshakanMasterKey: string
): Promise<ApiResponseBodyType<ZishaGyoshakanContact>> =>
  client.get<ZishaGyoshakanContact>(
    `${apiURLPrefix}/zisha_gyoshakan_masters/${zishaGyoshakanMasterKey}/contact`
  );
