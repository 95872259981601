import React, { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ReloadPageButton } from './parts/Button/ReloadPageButton';
import { uri } from '../constants/common/url/urlConstant';
import { cookieKeyBBto } from '../utils/dataUtil';
import { urlHelper } from '../constants/common/url/urlHelpers';
import { apiURLPrefix } from '../utils/api/urls';

// material-ui公式のThemeの型を利用してもprefer-readonly-parameter-typesが消えないため
// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
const useStyles = makeStyles((theme: Readonly<Theme>) => ({
  margin: {
    margin: theme.spacing(3)
  }
}));

export const Logout = (): JSX.Element => {
  useEffect(() => {
    const cookies = new Cookies();
    cookies.set(cookieKeyBBto, `${uri()}${urlHelper.top()}`, { path: '/' });
    window.location.href = `${apiURLPrefix}/sessions/delete`;
  }, []);

  const classes = useStyles();

  return (
    <div className={clsx(classes.margin)}>
      5秒経ってもページが自動で切り替わらない場合は、下部の「ページを更新する」をクリックするか、ページ更新してください。
      <ReloadPageButton />
    </div>
  );
};
