import React, { useMemo, createContext, useState } from 'react';

export const CsvExportPromotionContext = createContext(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as {
    csvExportPromotionRoomPropertyIds: ReadonlyArray<number>;
    setCsvExportPromotionRoomPropertyIds: React.Dispatch<
      React.SetStateAction<ReadonlyArray<number>>
    >;
  }
);

type Props = Readonly<{
  children: React.ReactNode;
}>;

// 賃貸部屋検索
export const CsvExportPromotionContextProvider: React.FC<Props> = ({
  children
}) => {
  const [
    csvExportPromotionRoomPropertyIds,
    setCsvExportPromotionRoomPropertyIds
  ] = useState<ReadonlyArray<number>>([]);

  const contextValue = useMemo(
    () => ({
      csvExportPromotionRoomPropertyIds,
      setCsvExportPromotionRoomPropertyIds
    }),
    [csvExportPromotionRoomPropertyIds, setCsvExportPromotionRoomPropertyIds]
  );

  return (
    <CsvExportPromotionContext.Provider value={contextValue}>
      {children}
    </CsvExportPromotionContext.Provider>
  );
};
