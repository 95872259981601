import { useState, useEffect } from 'react';
import { getAccountEdit } from '../utils/api/account';
import { Account } from '../types/account';

export const useAccount = (callable: boolean): Readonly<[Account, boolean]> => {
  const [account, setAccount] = useState<Account>({
    obi_name: '',
    contact_number: '',
    obi_addresss: '',
    belonging_organization: '',
    license_number: '',
    obi_image_url: '',
    obi_image: '',
    key: ''
  });
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    (async (): Promise<void> => {
      if (!callable) return;
      const [result, status] = await getAccountEdit();

      if (status === 'successful' && !('message' in result)) {
        setAccount(result);
      }
      setLoaded(true);
    })();
  }, [callable]);

  return [account, loaded];
};
