import { palette } from '../palette';

export const MuiOutlinedInput = {
  root: {
    borderRadius: 0,
    borderWidth: 2,
    '&:hover': {
      borderColor: palette.primary.main
    }
  },
  notchedOutline: {
    borderWidth: 2,
    borderColor: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      borderColor: palette.primary.main
    }
  },
  inputMarginDense: {
    paddingTop: '8.5px',
    paddingBottom: '8.5px'
  }
};
