export const MuiFlatPageButton = {
  sizeLargeStandard: {
    paddingLeft: 16,
    paddingRight: 16
  },
  sizeLargeCurrent: {
    paddingLeft: 16,
    paddingRight: 16
  },
  sizeLargeEnd: {
    paddingLeft: 16,
    paddingRight: 16
  }
};
