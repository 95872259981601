import React from 'react';
import {
  ItandiHeaderListItem,
  ItandiHeaderListItemRow
} from '@itandi/itandi-bb-ui';
import { urlHelper } from '../../../../constants/common/url/urlHelpers';

type Props = Readonly<{
  isActive: boolean;
}>;

export const RentMenu: React.FC<Props> = ({ isActive }: Props) => (
  <ItandiHeaderListItem label="賃貸物件" active={isActive}>
    <ItandiHeaderListItemRow url={urlHelper.rentRoomsList()}>
      居住用部屋
    </ItandiHeaderListItemRow>
    <ItandiHeaderListItemRow url={urlHelper.rentBusinessPropertiesList()}>
      事業用物件
    </ItandiHeaderListItemRow>
    <ItandiHeaderListItemRow url={urlHelper.rentLandsList()}>
      土地
    </ItandiHeaderListItemRow>
    <ItandiHeaderListItemRow url={urlHelper.rentParkingsList()}>
      駐車場
    </ItandiHeaderListItemRow>
  </ItandiHeaderListItem>
);
