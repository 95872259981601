import React, { useContext } from 'react';
import { Cookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import { cookieKeyBBto } from '../utils/dataUtil';
import { useLoginInfoOrRedirect } from '../hooks/useLoginInfoOrRedirect';
import { LoadingPage } from '../components/templates/Loading';
import { HeaderContext } from '../context/HeaderContext';
import { getHeader } from '../utils/api/header';
import { urlHelper } from '../constants/common/url/urlHelpers';

type Props = Readonly<{
  children: Readonly<JSX.Element>;
}>;

export const PrivateRoutes: React.FC<Props> = ({ children }) => {
  const [loginInfo, loadedLoginInfo] = useLoginInfoOrRedirect();
  const cookies = new Cookies();
  cookies.set(cookieKeyBBto, window.location.href, { path: '/' });

  const { headerPropsLoaded, setHeaderPropsLoaded, setHeaderProps } =
    useContext(HeaderContext);

  if (loginInfo.authenticated && !headerPropsLoaded) {
    (async (): Promise<void> => {
      const headerPropsResponse = await getHeader();
      if (headerPropsResponse[1] === 'successful') {
        setHeaderPropsLoaded && setHeaderPropsLoaded(true);
        setHeaderProps && setHeaderProps(headerPropsResponse[0]);
      }
    })();
  }

  if (!loadedLoginInfo || loginInfo.authenticated === undefined) {
    return <LoadingPage />;
  }

  if (!loginInfo.authenticated) {
    return <Redirect to={urlHelper.login()} />;
  }

  return children;
};
