import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// material-ui公式のThemeの型を利用してもprefer-readonly-parameter-typesが消えないため
// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
export const useLabelStyles = makeStyles((theme: Readonly<Theme>) =>
  createStyles({
    wrapper: {
      maxWidth: 800
    },
    flexBox: {
      display: 'flex',
      flexDirection: 'row'
    },
    label: {
      display: 'flex',
      fontSize: '0.875rem',
      width: '100%',
      color: theme.palette.ItandiGray800.main,
      background: '#eee',
      alignItems: 'center'
    },
    labelNormal: {
      padding: '0.4em 0.5em'
    },
    labelSmall: {
      padding: '0.2em 0.3em'
    },
    before: {
      width: 3,
      margin: '2px 4px',
      backgroundColor: theme.palette.common.white
    },
    space: {
      padding: 3
    },
    size: {
      fontWeight: 700,
      width: '100%'
    },
    svg: {
      marginTop: 2,
      fontSize: 16
    },
    tableLabel: {
      display: 'flex',
      fontSize: '1rem',
      width: '100%',
      color: theme.palette.ItandiGray800.main,
      padding: '0.4em 0.5em',
      background: '#eee',
      borderLeft: 'solid 5px #FF9141'
    },
    title: {
      fontWeight: 600
    },
    /*
        subtitleSmall: {
            fontSize: 12,
            fontWeight: 600,
        },
        subtitleSpaceSmall: {
            paddingTop: 5,
        },
         */
    subtitleSpaceOtherSmall: {
      paddingTop: 3
    },
    subtitleSmallOld: {
      fontSize: 14,
      fontWeight: 600
    },
    subtitleSpaceSmallOld: {
      paddingTop: 7
    },
    booking: {
      marginTop: '-3px',
      marginLeft: 2,
      marginRight: 2
    },
    spaceList: {
      paddingLeft: 30,
      paddingBottom: 6,
      paddingTop: 5
    },
    spaceSmall: {
      padding: 2
    },
    subtitle: {
      lineHeight: '1.5',
      fontWeight: 600
    },
    subtitleSmall: {
      fontSize: '0.875rem',
      fontWeight: 600
    },
    subtitleSpace: {
      paddingTop: 7
    },
    subtitleSpaceSmall: {
      paddingTop: 4
    },
    marker: {
      fontSize: 24,
      width: 70,
      height: 70,
      paddingTop: 5
    },
    required: {
      backgroundColor: theme.palette.error.main
    },
    info: {
      backgroundColor: theme.palette.info.main
    },
    mark: {
      color: theme.palette.common.white,
      border: 0,
      height: 18,
      margin: '2px 4px',
      display: 'inline-block',
      padding: '0 12px',
      fontSize: 10,
      lineHeight: '18px',
      whiteSpace: 'nowrap',
      borderRadius: 16,
      fontWeight: 700
    },
    labelWithHelp: {
      display: 'flex',
      fontSize: '0.875rem',
      width: '100%',
      color: theme.palette.ItandiGray800.main,
      padding: '0.15em 0.5em',
      background: theme.palette.ItandiGray100.main,
      alignItems: 'center'
    },
    fontWeight: {
      fontWeight: 700
    },
    svgIcon: {
      fontSize: 18,
      marginTop: 3
    },
    HelpMsgIcon: {
      paddingTop: 2
    },
    svgIconSharp: {
      marginTop: 11
    },
    icon: {
      verticalAlign: 'bottom',
      fontSize: 30
    }
  })
);
