import { ApiResponseBodyType, client } from '../../client';
import { apiURLPrefix } from '../urls';

export type ZishaGyoshakanAuthentication = Readonly<{
  authenticated: boolean;
}>;

export const getZishaGyoshakanBrokerageCompanyAuthentication = async (
  zishaGyoshakanMasterKey: string
): Promise<ApiResponseBodyType<ZishaGyoshakanAuthentication>> =>
  client.get<ZishaGyoshakanAuthentication>(
    `${apiURLPrefix}/zisha_gyoshakan_masters/${zishaGyoshakanMasterKey}/authentication`
  );
