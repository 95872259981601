import { ApiResponseBodyType, client } from '../../client';
import { apiURLPrefix } from '../urls';

type ZishaGyoshakanMasterLogoImage = Readonly<{
  url: string;
}>;

export const getZishaGyoshakanMasterLogoImage = async (
  zishaGyoshakanMasterKey: string
): Promise<ApiResponseBodyType<ZishaGyoshakanMasterLogoImage>> =>
  client.get<ZishaGyoshakanMasterLogoImage>(
    `${apiURLPrefix}/zisha_gyoshakan_masters/${zishaGyoshakanMasterKey}/logo_image`
  );
