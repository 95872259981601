import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Account } from '../../../types/account';
import { daiwalivingUrlHelper } from '../../../constants/common/url/urlHelpers';

const useStyles = makeStyles(() => ({
  MessageWrapper: {
    maxWidth: '960px',
    margin: '20px auto 40px',
    padding: '25px',
    textAlign: 'left',
    fontSize: '16px'
  },
  Description: {
    marginBottom: '20px'
  },
  AnnoucedBy: {
    marginBottom: '20px',
    textAlign: 'right'
  },
  PrivacyPolicy: {
    display: 'inline-block',
    marginBottom: '20px',
    textDecoration: 'underline'
  },
  Contact: {
    marginBottom: '40px'
  },
  ContactUrl: {
    textDecoration: 'underline'
  },
  CoutionTexts: {
    marginBottom: '20px',
    '& > li:before': {
      content: '"※ "'
    },
    '& > li': {
      paddingLeft: '14px',
      textIndent: '-14px'
    }
  },
  Key: {
    textAlign: 'center'
  }
}));

type Props = Readonly<{
  account: Account;
}>;

export const ZishaGyoshakanForbiddenDaiwaContent: React.FC<Props> = ({
  account
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.MessageWrapper}>
      <Typography align="center" gutterBottom variant="h3">
        D-Partner会に関するお問合せフォーム
      </Typography>
      <p className={classes.Description}>
        平素は大変お世話になっております。
        <br />
        この度は弊社「D-Partner」会へお問合せを頂きまして誠にありがとうございます。大変お手数ですが、以下のお問い合わせフォームの内容を記入のうえご送信ください。
        <br />
        翌営業日以降に、担当営業所よりご連絡致します。
        <br />
        どうぞよろしくお願い申し上げます。
      </p>
      <p className={classes.AnnoucedBy}>
        大和リビング株式会社 D-Partner会事務局
      </p>
      [ 担当営業所 ]
      <ul>
        <li>営業時間：10:00～17:00</li>
        <li>定休日：水･日･祝･夏季･年末年始</li>
      </ul>
      <a
        href={daiwalivingUrlHelper.privacyPolicy()}
        rel="noopener noreferrer"
        target="_blank"
        className={classes.PrivacyPolicy}
      >
        プライバシーポリシー
      </a>
      <p className={classes.Contact}>
        お問い合わせフォームは
        <a
          href={daiwalivingUrlHelper.dPartnerContact(account.key)}
          rel="noopener noreferrer"
          target="_blank"
          className={classes.ContactUrl}
        >
          こちら
        </a>
      </p>
      <ul className={classes.CoutionTexts}>
        <li>権限がある場合には、自動で専用ページへ遷移いたします。</li>
        <li>
          店舗ごとに申請情報を承認しておりますので、専用ページへの権限があるはずにもかかわらずログインできない場合には、ログアウトしてから再度ログインしてください。
        </li>
      </ul>
      <p className={classes.Key}>【D-Partner申請用KEY】{account.key}</p>
    </div>
  );
};
