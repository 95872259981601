import { useState, useEffect } from 'react';
import { getZishaGyoshakanManagementCompanyAuthentication } from '../utils/api/zishaGyoshakanMaster/authentication';
import { ZishaGyoshakanAuthentication } from '../utils/api/zishaGyoshakanMasters/authentication';

export const useZishaGyoshakanManagementCompanyAuthentication = (): Readonly<
  [ZishaGyoshakanAuthentication, boolean]
> => {
  const [
    zishaGyoshakanManagementCompanyAuthentication,
    setZishaGyoshakanManagementCompanyAuthentication
  ] = useState<ZishaGyoshakanAuthentication>({
    authenticated: false
  });
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    (async (): Promise<void> => {
      const [result, status] =
        await getZishaGyoshakanManagementCompanyAuthentication();

      if (status === 'successful' && !('message' in result)) {
        setZishaGyoshakanManagementCompanyAuthentication(result);
      }
      setLoaded(true);
    })();
  }, []);

  return [zishaGyoshakanManagementCompanyAuthentication, loaded];
};
