import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { jaJP } from '@material-ui/core/locale';
import { palette } from './palette';
import { typography } from './typography';
import { overrides } from './overridesSearch';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    ITANDIBB_BB: Palette['primary'];
    ITANDIBB_NK: Palette['primary'];
    ITANDIBB_MU: Palette['primary'];
    ITANDIExpressiveAquaDark: Palette['primary'];
    ITANDISemanticPositive: Palette['primary'];
    ITANDISemanticPositive100: Palette['primary'];
    ITANDISemanticNegative: Palette['primary'];
    ITANDISemanticNegative100: Palette['primary'];
    ITANDISemanticWarning: Palette['primary'];
    ITANDISemanticWarning100: Palette['primary'];
    ITANDISemanticLink: Palette['primary'];
    ItandiGray50: Palette['primary'];
    ItandiGray100: Palette['primary'];
    ItandiGray200: Palette['primary'];
    ItandiGray300: Palette['primary'];
    ItandiGray400: Palette['primary'];
    ItandiGray500: Palette['primary'];
    ItandiGray600: Palette['primary'];
    ItandiGray700: Palette['primary'];
    ItandiGray800: Palette['primary'];
    ItandiGray900: Palette['primary'];
  }
  interface PaletteOptions {
    ITANDIBB_BB: PaletteOptions['primary'];
    ITANDIBB_NK: PaletteOptions['primary'];
    ITANDIBB_MU: PaletteOptions['primary'];
    ITANDIExpressiveAquaDark: PaletteOptions['primary'];
    ITANDISemanticPositive: PaletteOptions['primary'];
    ITANDISemanticPositive100: PaletteOptions['primary'];
    ITANDISemanticNegative: PaletteOptions['primary'];
    ITANDISemanticNegative100: PaletteOptions['primary'];
    ITANDISemanticWarning: PaletteOptions['primary'];
    ITANDISemanticWarning100: PaletteOptions['primary'];
    ITANDISemanticLink: PaletteOptions['primary'];
    ItandiGray50: PaletteOptions['primary'];
    ItandiGray100: PaletteOptions['primary'];
    ItandiGray200: PaletteOptions['primary'];
    ItandiGray300: PaletteOptions['primary'];
    ItandiGray400: PaletteOptions['primary'];
    ItandiGray500: PaletteOptions['primary'];
    ItandiGray600: PaletteOptions['primary'];
    ItandiGray700: PaletteOptions['primary'];
    ItandiGray800: PaletteOptions['primary'];
    ItandiGray900: PaletteOptions['primary'];
  }
}

const baseTheme = {
  spacing: 4,
  palette,
  typography,
  overrides
};

export const theme = responsiveFontSizes(createTheme(baseTheme, jaJP));
