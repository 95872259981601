import React, { useMemo, createContext } from 'react';
import { useWatchMediaQueryChange } from '../hooks/useWatchMediaQueryChange';

export const GlobalContext = createContext(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as {
    isLaptop: boolean;
    isLaptopAndTabletHorizontal: boolean;
    isLaptopAndTablet: boolean;
    isMobile: boolean;
  }
);

type Props = Readonly<{
  children: React.ReactNode;
}>;

export const GlobalContextProvider: React.FC<Props> = ({ children }) => {
  const { isLaptop, isLaptopAndTabletHorizontal, isLaptopAndTablet, isMobile } =
    useWatchMediaQueryChange();

  const contextValue = useMemo(
    () => ({
      isLaptop,
      isLaptopAndTabletHorizontal,
      isLaptopAndTablet,
      isMobile
    }),
    [isLaptop, isLaptopAndTabletHorizontal, isLaptopAndTablet, isMobile]
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};
