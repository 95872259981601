import React, { useMemo, createContext, useState } from 'react';
import { searchFormFieldsListRentParkingDefault } from '../constants/rentProperty';
import { JgdcCode } from '../types/jgdcCode';
import { useSearchFormJgdcCodes } from '../hooks/useSearchFormJgdcCodes';
import { useBool } from '../hooks/util/useBool';
import { PropertySearchModeLimited } from '../types/propertySearchParams/mode';
import { SearchablePropertySort } from '../types/propertySearchParams/sort';

export const RentParkingGlobalContext = createContext(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as {
    rentParkingSearchSelected: Readonly<
      typeof searchFormFieldsListRentParkingDefault
    >;
    setRentParkingSearchSelected: (
      searchSelected: typeof searchFormFieldsListRentParkingDefault
    ) => void;
    rentParkingMode: PropertySearchModeLimited;
    setRentParkingMode: (mode: PropertySearchModeLimited) => void;
    searchFormJgdcCodes: ReadonlyArray<JgdcCode>;
    setFilteredSearchFormJgdcCodes: (
      jgdcCodes: ReadonlyArray<JgdcCode>
    ) => void;
    rentParkingOffset: number;
    setRentParkingOffset: (offset: number) => void;
    rentParkingSelectedSort: SearchablePropertySort;
    setRentParkingSelectedSort: (sort: SearchablePropertySort) => void;
    isRentParkingListDrawerOpened: boolean;
    openRentParkingListDrawer: () => void;
    closeRentParkingListDrawer: () => void;
  }
);

type Props = Readonly<{
  children: React.ReactNode;
}>;

export const RentParkingGlobalContextProvider: React.FC<Props> = ({
  children
}) => {
  const [rentParkingSearchSelected, setRentParkingSearchSelected] = useState<
    Readonly<typeof searchFormFieldsListRentParkingDefault>
  >(searchFormFieldsListRentParkingDefault);
  const [rentParkingMode, setRentParkingMode] =
    useState<PropertySearchModeLimited>('single');
  const [searchFormJgdcCodes, setFilteredSearchFormJgdcCodes] =
    useSearchFormJgdcCodes();
  const [rentParkingOffset, setRentParkingOffset] = useState<number>(1);
  const [rentParkingSelectedSort, setRentParkingSelectedSort] =
    useState<SearchablePropertySort>('last_status_opened_at desc');
  const [
    isRentParkingListDrawerOpened,
    openRentParkingListDrawer,
    closeRentParkingListDrawer
  ] = useBool(true);

  const contextValue = useMemo(
    () => ({
      rentParkingSearchSelected,
      setRentParkingSearchSelected,
      rentParkingMode,
      setRentParkingMode,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      rentParkingOffset,
      setRentParkingOffset,
      rentParkingSelectedSort,
      setRentParkingSelectedSort,
      isRentParkingListDrawerOpened,
      openRentParkingListDrawer,
      closeRentParkingListDrawer
    }),
    [
      rentParkingSearchSelected,
      setRentParkingSearchSelected,
      rentParkingMode,
      setRentParkingMode,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      rentParkingOffset,
      setRentParkingOffset,
      rentParkingSelectedSort,
      setRentParkingSelectedSort,
      isRentParkingListDrawerOpened,
      openRentParkingListDrawer,
      closeRentParkingListDrawer
    ]
  );

  return (
    <RentParkingGlobalContext.Provider value={contextValue}>
      {children}
    </RentParkingGlobalContext.Provider>
  );
};
