import { palette } from '../palette';

export const MuiFab = {
  root: {
    fontWeight: 700,
    boxShadow: '0 3px 6px 0 rgba(0,0,0,0.1)',
    color: palette.common.white,
    backgroundColor: 'transparent',
    transition: '.4s',
    fill: palette.common.white,
    '&:hover': {
      color: palette.common.white,
      textDecoration: 'none',
      boxShadow: '0 10px 20px 0 rgba(0,0,0,0.1)',
      transform: 'translateY(-2px)',
      backgroundColor: 'transparent'
    },
    '&:focus': {
      color: palette.common.white,
      textDecoration: 'none',
      boxShadow: '0 10px 20px 0 rgba(0,0,0,0.1)',
      transform: 'translateY(-2px)',
      backgroundColor: 'transparent'
    }
  },
  primary: {
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.light,
      '@media (hover: none)': {
        backgroundColor: palette.primary.light
      }
    },
    '&:focus': {
      backgroundColor: palette.primary.light,
      '@media (hover: none)': {
        backgroundColor: palette.primary.light
      }
    }
  },
  secondary: {
    backgroundColor: palette.secondary.main,
    '&:hover': {
      backgroundColor: palette.secondary.light,
      '@media (hover: none)': {
        backgroundColor: palette.secondary.light
      }
    },
    '&:focus': {
      backgroundColor: palette.secondary.light,
      '@media (hover: none)': {
        backgroundColor: palette.secondary.light
      }
    }
  }
};
