import axios from 'axios';
import qs from 'qs';
import { withScope, captureException, flush, Scope } from '@sentry/browser';
import { Cookies } from 'react-cookie';
import { urlHelper } from '../constants/common/url/urlHelpers';
import { cookieKeyCsrfToken } from './dataUtil';

export const badRequestBodyMessageTooManyRooms = 'bad request. too many rooms';

export type OkResponseBody = Readonly<{
  message: 'ok';
}>;

export const BadRequestResponseBody = {
  message: 'bad request'
};

type Status302Type = Readonly<{
  status_code: 302;
  message: string;
  redirect_to: string;
}>;

export type ApiResponseBodyType<SuccessfulResponseBody> =
  | SuccessfulResponse<SuccessfulResponseBody>
  | BadRequestResponse
  | ForbiddenResponse
  | NotFoundResponse;

export type SuccessfulResponseBodyType<T> = Extract<
  T,
  SuccessfulResponse<Record<string, unknown>>
>[0];

// TODO: この型は別ファイルからはimportしないようにして、全般的にCreatedResponseBodyを使うようにする
export type CreatedResponseBody = Readonly<{
  message: 'created';
}>;

export type ErrorResponseBody = Readonly<{
  message: string;
}>;

export type AxiosErrorResponse = Readonly<{
  response: Readonly<{
    data: Readonly<{
      status_code: number;
      message: string;
    }>;
  }>;
}>;

export type SuccessfulResponse<SuccessfulResponseBody> = Readonly<
  [SuccessfulResponseBody, 'successful']
>;
export type OkResponse = Readonly<[OkResponseBody, 'successful']>;
export type CreatedResponse = Readonly<[CreatedResponseBody, 'successful']>;
export type BadRequestResponse = Readonly<[ErrorResponseBody, 'badRequest']>;
export type UnauthorizedResponse = Readonly<
  [ErrorResponseBody, 'unauthorized']
>;
export type ForbiddenResponse = Readonly<[ErrorResponseBody, 'forbidden']>;
export type NotFoundResponse = Readonly<[ErrorResponseBody, 'notFound']>;
export type InternalServerErrorResponse = Readonly<
  [ErrorResponseBody, 'internalServerError']
>;

export type FileResponse = Readonly<
  [
    Readonly<{
      status_code?: string;
      blob: Readonly<Blob>;
      type?: string;
      filename?: string;
    }>,
    'successful'
  ]
>;

const createGetParams = (params = {}): string =>
  qs.stringify(params, { arrayFormat: 'indices', addQueryPrefix: true });

export const badRequestResponse: BadRequestResponse = [
  { message: 'bad request' },
  'badRequest'
];

export const forbiddenResponse: ForbiddenResponse = [
  { message: 'forbidden' },
  'forbidden'
];

export const notFoundResponse: NotFoundResponse = [
  { message: 'not found' },
  'notFound'
];

export const internalServerErrorResponse: InternalServerErrorResponse = [
  { message: 'internal server error' },
  'internalServerError'
];

const getFileName = (
  response: Readonly<{ headers: Readonly<{ 'content-disposition': string }> }>
): string => {
  const filenameMatch = (response.headers['content-disposition'] || '').match(
    /filename\*?=(?:UTF-8'')?([^";]+)(?:;|$)/i
  );

  return filenameMatch ? decodeURIComponent(filenameMatch[1]) : '';
};

const returnErrorResponse = async (
  error: unknown
): Promise<NotFoundResponse | ForbiddenResponse | BadRequestResponse> => {
  withScope((scope: Readonly<Scope>) => {
    scope.setTag('scene', 'api');
    captureException(error);
  });
  await flush(2000);
  let redirectPath = urlHelper.error();
  if (axios.isAxiosError(error) && error.response) {
    if (error.response.status === 429) {
      redirectPath = urlHelper.error429Private();
    }
    if (error.response.status === 404) {
      return [{ message: error.response.data }, 'notFound'];
    }
    if (error.response.status === 403) {
      return [{ message: error.response.data }, 'forbidden'];
    }
    if (error.response.status === 401) {
      redirectPath = urlHelper.login();
    }
    if (error.response.status === 400) {
      return [{ message: error.response.data }, 'badRequest'];
    }
  }
  await new Promise(() => {
    window.location.href = redirectPath;
  });
  // NOTE: 型エラー回避のために記述している。以下の値がresponseとして返ることはない.
  return notFoundResponse;
};

// TODO: すべてこちらのロジックを使った実装に置き換える
//       returnErrorResponseの場合は[{message: {message: string}}, 'notFound']のような構造で返ってきてしまうため
//       型と実態が乖離してしまっている
//       置き換えが完了したら、returnErrorResponseにrenameする
const returnErrorResponseBodyTypeCorrectable = async (
  error: unknown
): Promise<NotFoundResponse | ForbiddenResponse | BadRequestResponse> => {
  withScope((scope: Readonly<Scope>) => {
    scope.setTag('scene', 'api');
    captureException(error);
  });
  await flush(2000);
  let redirectPath = urlHelper.error();
  if (axios.isAxiosError(error) && error.response) {
    if (error.response.status === 429) {
      redirectPath = urlHelper.error429Private();
    }
    if (error.response.status === 404) {
      return [{ ...error.response.data }, 'notFound'];
    }
    if (error.response.status === 403) {
      return [{ ...error.response.data }, 'forbidden'];
    }
    if (error.response.status === 401) {
      redirectPath = urlHelper.login();
    }
    if (error.response.status === 400) {
      return [{ ...error.response.data }, 'badRequest'];
    }
  }
  await new Promise(() => {
    window.location.href = redirectPath;
  });
  // NOTE: 型エラー回避のために記述している。以下の値がresponseとして返ることはない.
  return notFoundResponse;
};

class Client {
  private readonly instance;

  constructor() {
    this.instance = axios.create({
      headers: { Pragma: 'no-cache' },
      withCredentials: true
    });
  }

  public async get<SuccessfulResponseType>(
    path: string
  ): Promise<
    | SuccessfulResponse<SuccessfulResponseType>
    | NotFoundResponse
    | ForbiddenResponse
    | BadRequestResponse
  > {
    try {
      /* eslint-disable @typescript-eslint/consistent-type-assertions */
      const response = (await this.instance.get(path)) as {
        data: SuccessfulResponseType;
      };
      return [response.data, 'successful'];
    } catch (error) {
      return returnErrorResponse(error);
    }
  }

  public async getErrorNotTransitionServerError<SuccessfulResponseType>(
    path: string
  ): Promise<
    | SuccessfulResponse<SuccessfulResponseType>
    | NotFoundResponse
    | ForbiddenResponse
    | BadRequestResponse
    | InternalServerErrorResponse
  > {
    try {
      const response = (await this.instance.get(path)) as {
        data: SuccessfulResponseType;
      };
      return [response.data, 'successful'];
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 500) {
          return [{ ...error.response.data }, 'internalServerError'];
        }
        return returnErrorResponseBodyTypeCorrectable(error);
      }
      // 型エラー回避のために記述
      return notFoundResponse;
    }
  }

  public async post(
    path: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    params: {}
  ): Promise<
    CreatedResponse | NotFoundResponse | ForbiddenResponse | BadRequestResponse
  > {
    try {
      const response = (await this.instance.post(path, params, {
        headers: { 'X-CSRF-TOKEN': new Cookies().get(cookieKeyCsrfToken) }
      })) as {
        data: CreatedResponseBody;
      };
      return [response.data, 'successful'];
    } catch (error) {
      return returnErrorResponse(error);
    }
  }

  public async postCustomResponse<SuccessfulResponseBody>(
    path: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    params: {}
  ): Promise<
    | SuccessfulResponse<SuccessfulResponseBody>
    | NotFoundResponse
    | ForbiddenResponse
    | BadRequestResponse
  > {
    try {
      const response = (await this.instance.post(path, params, {
        headers: { 'X-CSRF-TOKEN': new Cookies().get(cookieKeyCsrfToken) }
      })) as {
        data: SuccessfulResponseBody;
      };
      return [response.data, 'successful'];
    } catch (error) {
      return returnErrorResponseBodyTypeCorrectable(error);
    }
  }

  public async postErrorThrough<SuccessfulResponseBody>(
    path: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    params: {}
  ): Promise<
    | SuccessfulResponse<SuccessfulResponseBody>
    | NotFoundResponse
    | ForbiddenResponse
    | BadRequestResponse
    | InternalServerErrorResponse
  > {
    try {
      const response = (await this.instance.post(path, params, {
        headers: { 'X-CSRF-TOKEN': new Cookies().get(cookieKeyCsrfToken) }
      })) as {
        data: SuccessfulResponseBody;
      };
      return [response.data, 'successful'];
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 500) {
          return [{ ...error.response.data }, 'internalServerError'];
        }
        return returnErrorResponseBodyTypeCorrectable(error);
      }
      return notFoundResponse;
    }
  }

  public async put(
    path: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    params: {} = {}
  ): Promise<
    OkResponse | NotFoundResponse | ForbiddenResponse | BadRequestResponse
  > {
    try {
      const response = (await this.instance.put(path, params, {
        headers: { 'X-CSRF-TOKEN': new Cookies().get(cookieKeyCsrfToken) }
      })) as {
        data: OkResponseBody;
      };
      return [response.data, 'successful'];
    } catch (error) {
      return returnErrorResponse(error);
    }
  }

  public async putCustomResponse<SuccessfulResponseBody>(
    path: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    params: {} = {}
  ): Promise<
    | SuccessfulResponse<SuccessfulResponseBody>
    | NotFoundResponse
    | ForbiddenResponse
    | BadRequestResponse
  > {
    try {
      const response = (await this.instance.put(path, params, {
        headers: { 'X-CSRF-TOKEN': new Cookies().get(cookieKeyCsrfToken) }
      })) as {
        data: SuccessfulResponseBody;
      };
      return [response.data, 'successful'];
    } catch (error) {
      return returnErrorResponseBodyTypeCorrectable(error);
    }
  }

  public async delete<SuccessfulResponseType>(
    path: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    params: {}
  ): Promise<
    | SuccessfulResponse<SuccessfulResponseType>
    | NotFoundResponse
    | ForbiddenResponse
    | BadRequestResponse
  > {
    try {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const response = (await this.instance.delete(
        `${path}${params ? createGetParams(params) : ''}`,
        {
          headers: { 'X-CSRF-TOKEN': new Cookies().get(cookieKeyCsrfToken) }
        }
      )) as {
        data: SuccessfulResponseType;
      };
      return [response.data, 'successful'];
    } catch (error) {
      return returnErrorResponse(error);
    }
  }

  public async getFileCatchErrorMsg(path: string): Promise<FileResponse> {
    try {
      /* eslint-disable @typescript-eslint/consistent-type-assertions */
      const response = (await this.instance.get(path, {
        responseType: 'blob'
      })) as {
        data: Blob;
      };
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const type = blob.type.split('/');
      if (type[1] === 'json') {
        throw Error;
      }
      return [{ blob }, 'successful'];
    } catch {
      return this.instance
        .get(path)
        .catch((e: AxiosErrorResponse) => [
          { status_code: String(e.response.data.status_code), blob: {} },
          'successful'
        ]) as unknown as FileResponse;
    }
  }

  public async getFileErrorThrough(
    path: string
  ): Promise<FileResponse | [false, 'successful']> {
    try {
      /* eslint-disable @typescript-eslint/consistent-type-assertions */
      const response = (await this.instance.get(path, {
        responseType: 'blob'
      })) as Readonly<{
        data: Blob;
        headers: Readonly<{ 'content-disposition': string }>;
      }>;
      const blob = new Blob([response.data], {
        type: response.data.type
      });

      const type = blob.type.split('/');
      if (type[1] === 'json') {
        return [false, 'successful'];
      }
      return [
        { blob, type: type[1], filename: getFileName(response) },
        'successful'
      ];
    } catch (error) {
      return [false, 'successful'];
    }
  }

  public async postFileCatchErrorMsg(
    path: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    params: {}
  ): Promise<[{ blob?: Blob; error?: number }, string]> {
    try {
      const response = (await this.instance.post(path, params, {
        headers: { 'X-CSRF-TOKEN': new Cookies().get(cookieKeyCsrfToken) },
        responseType: 'blob'
      })) as {
        data: Blob;
      };
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const type = blob.type.split('/');
      if (type[1] === 'json') {
        throw Error;
      }
      return [{ blob }, 'successful'];
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return [{ error: error.response.status }, 'successful'];
      }
      return [{ error: 0 }, 'successful'];
    }
  }

  public async getFromPublicPage<
    SuccessfulResponseType extends Record<string, unknown>
  >(
    path: string
  ): Promise<
    | SuccessfulResponse<SuccessfulResponseType>
    | NotFoundResponse
    | ForbiddenResponse
    | BadRequestResponse
  > {
    try {
      /* eslint-disable @typescript-eslint/consistent-type-assertions */
      const response = (await this.instance.get(path)) as {
        data: SuccessfulResponseType | Status302Type;
      };
      // 302対応
      if (
        'status_code' in response.data &&
        response.data?.status_code === 302
      ) {
        window.location.href = response.data?.redirect_to as string;
      }
      return [response.data as SuccessfulResponseType, 'successful'];
    } catch (error) {
      let redirectPath = urlHelper.error500();
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 429) {
          redirectPath = urlHelper.error429();
        }
        if (error.response.status === 403) {
          redirectPath = urlHelper.error403();
        }
        if (error.response.status === 401) {
          redirectPath = urlHelper.login();
        }
      }
      await new Promise(() => {
        window.location.href = redirectPath;
      });
      // NOTE: 型エラー回避のために記述している。以下の値がresponseとして返ることはない.
      return notFoundResponse;
    }
  }

  public async getOnlyThrowTooManyRequestAndInternalServerError<
    SuccessfulResponseType
  >(
    path: string
  ): Promise<
    | SuccessfulResponse<SuccessfulResponseType>
    | NotFoundResponse
    | ForbiddenResponse
    | UnauthorizedResponse
    | BadRequestResponse
    | InternalServerErrorResponse
  > {
    try {
      /* eslint-disable @typescript-eslint/consistent-type-assertions */
      const response = (await this.instance.get(path)) as {
        data: SuccessfulResponseType;
      };
      return [response.data as SuccessfulResponseType, 'successful'];
    } catch (error) {
      let redirectPath = urlHelper.error500();
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 429) {
          redirectPath = urlHelper.error429Private();
        }
        if (error.response.status === 404) {
          return [{ message: error.response.data }, 'notFound'];
        }
        if (error.response.status === 403) {
          return [{ message: error.response.data }, 'forbidden'];
        }
        if (error.response.status === 401) {
          return [{ message: error.response.data }, 'unauthorized'];
        }
        if (error.response.status === 400) {
          return [{ message: error.response.data }, 'badRequest'];
        }
      }
      await new Promise(() => {
        window.location.href = redirectPath;
      });
      // NOTE: 型エラー回避のために記述している。以下の値がresponseとして返ることはない.
      return notFoundResponse;
    }
  }

  public async getOnlyThrowTooManyRequest<SuccessfulResponseType>(
    path: string
  ): Promise<
    | SuccessfulResponse<SuccessfulResponseType>
    | NotFoundResponse
    | ForbiddenResponse
    | UnauthorizedResponse
    | BadRequestResponse
    | InternalServerErrorResponse
  > {
    try {
      /* eslint-disable @typescript-eslint/consistent-type-assertions */
      const response = (await this.instance.get(path)) as {
        data: SuccessfulResponseType;
      };
      return [response.data as SuccessfulResponseType, 'successful'];
    } catch (error) {
      let redirectPath = urlHelper.error500();
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 429) {
          redirectPath = urlHelper.error429Private();
        }
        if (error.response.status === 500) {
          return [{ message: error.response.data }, 'internalServerError'];
        }
        if (error.response.status === 404) {
          return [{ message: error.response.data }, 'notFound'];
        }
        if (error.response.status === 403) {
          return [{ message: error.response.data }, 'forbidden'];
        }
        if (error.response.status === 401) {
          return [{ message: error.response.data }, 'unauthorized'];
        }
        if (error.response.status === 400) {
          return [{ message: error.response.data }, 'badRequest'];
        }
      }
      await new Promise(() => {
        window.location.href = redirectPath;
      });
      // NOTE: 型エラー回避のために記述している。以下の値がresponseとして返ることはない.
      return notFoundResponse;
    }
  }
}

export const client = new Client();
