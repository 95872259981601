import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessageArea } from './ErrorMessageArea';
import { urlHelper } from '../../../constants/common/url/urlHelpers';
import { ZishaGyoshakanContact } from '../../../types/zishaGyoshakanContact';
import { ContentsText } from '../Text/ContentsText';
import { CategoryLabel } from '../Label/CategoryLabel';

const useStyles = makeStyles(() => ({
  MessageWrapper: {
    marginTop: '20px',
    textAlign: 'center',
    marginBottom: '40px'
  }
}));

type Props = Readonly<{
  zishaGyoshakanContact: ZishaGyoshakanContact;
}>;

export const ZishaGyoshakanForbiddenContent: React.FC<Props> = ({
  zishaGyoshakanContact
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.MessageWrapper}>
      <ErrorMessageArea
        title="403 forbidden"
        subtitle="こちらのページを見る権限がありません。"
        buttonAttributes={[
          { href: urlHelper.logout(), text: 'ログインページへ' }
        ]}
      />
      <ContentsText small={false}>
        管理会社様ごとの不動産業者間サイトのご利用には申請が必要です
        {zishaGyoshakanContact.email && zishaGyoshakanContact.phone_number && (
          <>
            <br />
            未申請の場合は下記お問い合わせ先より申請してください。
            <br />
            <br />
            <CategoryLabel icon="ri-mail-line" noLabel>
              {zishaGyoshakanContact.email}
            </CategoryLabel>
            <br />
            <CategoryLabel icon="ri-phone-line" noLabel>
              {zishaGyoshakanContact.phone_number}
            </CategoryLabel>
          </>
        )}
      </ContentsText>
    </div>
  );
};
