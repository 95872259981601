import { palette } from '../palette';

export const MuiSelect = {
  root: {
    borderWidth: 2
  },
  outlined: {
    borderRadius: 0,
    '&:after': {
      borderBottom: `2px solid ${palette.primary.main}`
    },
    '&:hover:not($disabled):before': {
      borderBottom: `2px solid ${palette.primary.main}`,
      '@media (hover: none)': {
        borderBottom: `1px solid ${palette.primary.main}`
      }
    }
  },
  select: {
    '&:focus': {
      borderRadius: 0,
      backgroundColor: palette.common.white
    }
  }
};
