import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { headerHeightPixel } from '../parts/Common/BrokerageCompanyHeader/BrokerageCompanyHeader';

const useStyles = makeStyles({
  // 100vh - headerの高さ - 84px (footerの高さ)
  Body: {
    minHeight: `calc(100vh - ${headerHeightPixel}px - 84px)`
  }
});

type Props = Readonly<{
  children: Readonly<JSX.Element>;
}>;

export const BrokerageCompanyMain: React.FC<Props> = ({ children }: Props) => {
  const classnames = useStyles();

  return <main className={classnames.Body}>{children}</main>;
};
