import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// material-ui公式のThemeの型を利用してもprefer-readonly-parameter-typesが消えないため
// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
export const useTextStyles = makeStyles((theme: Readonly<Theme>) =>
  createStyles({
    contents: {
      fontSize: 20,
      fontWeight: 700
    },
    contentsSmall: {
      fontSize: 14,
      fontWeight: 700
    },
    detailText: {
      fontSize: 11,
      fontWeight: 700,
      margin: '0px 5px 0px 0px',
      whiteSpace: 'normal'
    },
    textIcon: {
      border: `2px solid ${theme.palette.primary.main}`,
      padding: '0 5px',
      lineHeight: '22px',
      textAlign: 'center',
      // marginLeft: '-5px',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      fontWeight: 700,
      display: 'inline-block',
      borderRadius: 16,
      margin: '2px 0',
      fontSize: 0,
      whiteSpace: 'nowrap',
      verticalAlign: 'bottom',
      '& span': {
        fontSize: 11,
        lineHeight: '16px',
        verticalAlign: 'middle',
        display: 'inline-block'
      }
    },
    tag: {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    },
    required: {
      backgroundColor: theme.palette.error.main
    },
    info: {
      backgroundColor: theme.palette.info.main
    },
    success: {
      backgroundColor: theme.palette.success.main
    },
    warning: {
      backgroundColor: theme.palette.warning.main
    },
    error: {
      backgroundColor: theme.palette.error.main
    },
    large: {
      fontSize: 32,
      fontWeight: 700
    },
    small: {
      fontSize: 24,
      fontWeight: 700
    },
    largeUnit: {
      fontSize: 18,
      fontWeight: 700
    }
  })
);
