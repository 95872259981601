import { palette } from '../palette';

export const MuiChip = {
  root: {
    height: 'initial',
    minHeight: '24px !important',
    color: palette.ItandiGray900.main,
    backgroundColor: palette.ItandiGray50.main,
    border: `1px solid ${palette.ItandiGray200.main}`,
    fontWeight: 400,
    fontSize: 12
  }
};
