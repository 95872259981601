import { palette } from '../palette';

export const MuiFormLabel = {
  root: {
    fontWeight: 700,
    color: 'rgb(189, 189, 189)',
    '&$focused': {
      color: palette.primary.main
    },
    '&$error': {
      color: 'rgb(189, 189, 189)'
    }
  }
};
